import moment from 'moment'
import React from 'react'

const InvoiceModal = ({ formData, units, currencies, handleChange }) => {
    return (
        <dialog id="my_modal_4" className=" w-[600px] h-[640px]  mx-auto mt-[90px] items-center  px-4 py-4 rounded-md shadow-xl">
            <div className="items-center">
                <h3 className="font-bold text-lg">Invoice details</h3>
                <div>
                    <div className='flex items-center justify-between space-x-8'>
                        <div className='basis-[50%]'>
                            <label>Terms</label>
                            <input
                                type="text"
                                name="terms"
                                value={formData?.terms}
                                onChange={handleChange}
                                className='px-2 border border-gray-400 text-md h-8 w-full rounded-sm'
                            />
                        </div>
                        <div className='basis-[50%]'>
                            <label>Currency</label>
                            <select name="currency" onChange={handleChange} className='w-full border border-gray-400 h-8 rounded-sm'>
                                <option>{formData?.currency ? formData?.currency : 'Select currency'}</option>
                                {
                                    currencies.map(c => (
                                        <option>{c.Currency}</option>
                                    ))
                                }
                            </select>
                        </div>
                    </div>
                    <div className='flex items-center justify-between my-4 space-x-8'>
                        <div className='basis-[50%]'>
                            <label>Invoice</label>
                            <input
                                type="text"
                                name="invoice"
                                value={formData?.invoice}
                                onChange={handleChange}
                                className='px-2 border border-gray-400 text-md h-8 w-full rounded-sm'
                            />
                        </div>
                        <div className='basis-[50%]'>
                            <label>Invoice date</label>
                            <input
                                type="date"
                                name="invoice_date"
                                value={formData?.invoice_date}
                                onChange={handleChange}
                                className='px-2 border border-gray-400 text-md h-8 w-full rounded-sm mr-8'
                            />
                        </div>
                    </div>
                    <div className='flex items-center justify-between my-4  space-x-8'>
                        <div className='basis-[50%]'>
                            <label>Po</label>
                            <input
                                type="text"
                                name="po"
                                value={formData?.po}
                                onChange={handleChange}
                                className='px-2 border border-gray-400 text-md h-8 w-full rounded-sm'
                            />
                        </div>
                        <div className='basis-[50%]'>
                            <label>Po date</label>
                            <input
                                type="date"
                                name="po_date"
                                value={moment(formData?.po_date).format("YYYY-MM-DD") || ''}
                                onChange={handleChange}
                                className='px-2 border border-gray-400 text-md h-8 w-full rounded-sm'
                            />
                        </div>
                    </div>
                    <div>
                        <label>Invoice description</label>
                        <textarea name="invoice_description" value={formData?.invoice_description} onChange={handleChange} className='w-full border border-gray-400 rounded-sm'></textarea>
                    </div>
                    <div className='flex items-center justify-between my-4  space-x-8'>
                        <div className='basis-[50%]'>
                            <label>Quantity (QTY)</label>
                            <input
                                type="text"
                                name='qty'
                                value={formData?.qty}
                                onChange={handleChange}
                                className='px-2 border border-gray-400 text-md h-8 w-full rounded-sm'
                            />
                        </div>
                        <div className='basis-[50%]'>
                            <label>Unit</label>
                            <select name="unit" onChange={handleChange} className='w-full h-8 border border-gray-400 rounded-sm px-2'>
                                <option>{formData?.unit ? formData?.unit : 'Select unit'}</option>
                                {
                                    units.map(u => (
                                        <option key={u.Indexid} value={u.uom}>{u.uom}</option>
                                    ))
                                }
                            </select>
                        </div>
                    </div>
                    <div className='flex items-center justify-between my-4  space-x-8'>
                        <div className='basis-[50%]'>
                            <label>Unit price</label>
                            <input
                                type="text"
                                name="unit_price"
                                value={formData?.unit_price}
                                onChange={handleChange}
                                className='px-2 border border-gray-400 text-md h-8 w-full rounded-sm'
                            />
                        </div>
                        <div className='basis-[50%]'>
                            <label>Unit value</label>
                            <input
                                type="text"
                                name="unit_value"
                                value={formData?.unit_value}
                                onChange={handleChange}
                                className='px-2 border border-gray-400 text-md h-8 w-full rounded-sm'
                            />
                        </div>
                    </div>
                </div>
                <div className="modal-action">
                    <form method="dialog">
                        {/* if there is a button, it will close the modal */}
                        <button className="px-4 py-1 bg-blue-500 text-white">Close</button>
                    </form>
                </div>
            </div >
        </dialog >
    )
}

export default InvoiceModal