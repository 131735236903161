import moment from 'moment'
import React from 'react'

const Transfer = ({ formData, units, handleChange, serviceType }) => {
    console.log(serviceType)
    return (
        <div>
            <div className='flex items-center space-x-4'>
                <div>
                    <label>Place of shipment</label>
                    <input
                        type="text"
                        name="place_of_shipment"
                        value={formData?.place_of_shipment}
                        onChange={handleChange}
                        className='w-full h-8 border border-gray-400 rounded-md px-2'
                    />
                </div>
                <div>
                    <label>Place of Delivery</label>
                    <input
                        type="text"
                        name="place_of_delivery"
                        value={formData?.place_of_delivery}
                        onChange={handleChange}
                        className='w-full h-8 border border-gray-400 rounded-md px-2'
                    />
                </div>
            </div>
            <div className='flex items-center space-x-4 my-2'>
                <div>
                    <label>Net wt</label>
                    <div className='flex items-center space-x-2'>
                        <input
                            type="text"
                            name="net_wt"
                            value={formData?.net_wt || ''}
                            className='px-2 w-[100px]  h-8 rounded-md border border-gray-400'
                            onChange={handleChange}
                        />
                        <select name="net_wt_unit" onChange={handleChange} className='w-full h-8 border border-gray-400 rounded-md px-2'>
                            <option>{formData?.net_wt_unit !== null ? formData?.net_wt_unit : 'Select net weight'}</option>
                            {units.map(ut => (
                                <option key={ut?.Indexid} value={ut?.uom}>{ut?.uom}</option>
                            ))}
                        </select>
                    </div>
                </div>
                <div>
                    <label>Gross wt</label>
                    <div className='flex items-center space-x-2'>
                        <input
                            type="text"
                            name="gross_wt"
                            value={formData?.gross_wt || ''}
                            className='px-2 w-[100px]  h-8 rounded-md border border-gray-400'
                            onChange={handleChange}

                        />
                        <select name="gross_wt_unit" onChange={handleChange} className='w-full h-8 border border-gray-400 rounded-md px-2'>
                            <option>{formData?.gross_wt_unit !== null ? formData?.gross_wt_unit : 'Select gross weight'}</option>
                            {units.map(ut => (
                                <option key={ut?.Indexid} value={ut?.uom}>{ut?.uom}</option>
                            ))}
                        </select>
                    </div>
                </div>
                <div>
                    <label>No of packages</label>
                    <div className='flex items-center space-x-2'>
                        <input
                            type="text"
                            name="num_of_pkgs"
                            value={formData?.num_of_pkgs || ''}
                            className='px-2 w-[100px]  h-8 rounded-md border border-gray-400'
                            onChange={handleChange}
                        />
                        <select name="num_of_pkgs_unit" onChange={handleChange} className='w-full h-8 border border-gray-400 rounded-md px-2'>
                            <option>{formData?.num_of_pkgs_unit !== null ? formData?.num_of_pkgs_unit : 'Select charges weight'}</option>
                            {units.map(ut => (
                                <option key={ut?.Indexid} value={ut?.uom}>{ut?.uom}</option>
                            ))}
                        </select>
                    </div>
                </div>
            </div>
            <div className='flex items-center space-x-4'>
                <div>
                    <label>Goods Rcvd On</label>
                    <input
                        type="date"
                        name="goods_rcvd_on"
                        value={formData?.goods_rcvd_on}
                        onChange={handleChange}
                        className='w-full h-8 border border-gray-400 rounded-md px-2'
                    />
                </div>
                {serviceType === 'Imports' && (
                    <>
                        <div>
                            <label>B.E No</label>
                            <input
                                type="text"
                                name="be_no"
                                value={formData?.be_no}
                                onChange={handleChange}
                                className='w-full h-8 border border-gray-400 rounded-md px-2'
                            />
                        </div>
                        <div>
                            <label>B.E date</label>
                            <input
                                type="date"
                                name="be_date"
                                value={moment(formData?.be_date).format("YYYY/MM/DD") || ''}
                                onChange={handleChange}
                                className='w-full h-8 border border-gray-400 rounded-md px-2'
                            />
                        </div>
                    </>
                )}
                {serviceType === 'Exports' && (
                    <>
                        <div>
                            <label>Certificate No</label>
                            <input
                                type="text"
                                name="certificate_no"
                                value={formData?.certificate_no}
                                onChange={handleChange}
                                className='w-full h-8 border border-gray-400 rounded-md px-2'
                            />
                        </div>
                        <div>
                            <label>Certificate date</label>
                            <input
                                type="date"
                                name="certificate_date"
                                value={moment(formData?.certificate_date).format("YYYY-MM-DD") || ''}
                                onChange={handleChange}
                                className='w-full h-8 border border-gray-400 rounded-md px-2'
                            />
                        </div>
                    </>
                )}
            </div>
        </div>
    )
}

export default Transfer