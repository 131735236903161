import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import './styles/FileDelete.css';
import { FaSearch } from 'react-icons/fa';
import Search from '../components/search/Search';

const BASE_URL = process.env.REACT_APP_BACKEND_URL + 'file-upload';

const FileDelete = () => {
  const [refNo, setRefNo] = useState('');
  const [open, setOpen] = useState(false);
  const [records, setRecords] = useState([]);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [files, setFiles] = useState([]);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const menuRef = useRef();




  const fetchFiles = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/search?q=${refNo}`);
      console.log(response.data);
      setRecords(response.data)
      setFiles(response.data);
      setOpen(true);

    } catch (err) {
      console.error('Error fetching files:', err);
      alert(err?.response?.data?.message);
      setError('Error fetching files');
    }
  };

  const handleRecordSelect = (record) => {
    setRefNo(record?.ref_no);
    setSelectedRecord(record?.ref_no);
    setOpen(false);
  };
  useEffect(() => {
    if (refNo !== '') {
      fetchFiles()
    }
    const handler = (e) => {
      if (!menuRef.current.contains(e.target)) {
        setOpen(false);
      }
    }
    document.addEventListener('mousedown', handler)
    return () => {
      document.removeEventListener("mousedown", handler);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refNo]);



  const handleDelete = async (fileId) => {
    try {
      await axios.delete(`${BASE_URL}/delete/${fileId}`);
      alert("File deleted !")
      setSuccessMessage('File deleted successfully');
      fetchFiles(refNo);
      setTimeout(() => {
        setSuccessMessage('');
      }, 5000);
    } catch (err) {
      alert('Error deleting file !')
      console.error('Error deleting file:', err);
      setError('Error deleting file');
      setTimeout(() => {
        setError('');
      }, 5000);
    }
  };



  console.log(files);
  return (
    <div className="file-delete-container">
      <h1 className="file-delete-title">Delete Files</h1>

      <div className="file-delete-form">
        <div className='relative' ref={menuRef}>
          <div className='w-[350px] mx-auto flex items-center space-x-4 border border-gray-400 px-2 rounded-[5px]'>
            <input
              type="text"
              value={refNo || ''}
              onChange={(e) => setRefNo(e.target.value)}
              className='w-full  px-2 py-2 rounded-sm outline-0'
              placeholder='Search reference no'
            />
            <span className='text-blue-600 font-bold text-sm'>{selectedRecord?.ref_no}</span>
            <FaSearch size='16' />
          </div>
          {(records.length > 0 && open) && (
            <ul className='w-[350px] max-h-[300px] overflow-y-auto shadow-lg bg-white px-2 mt-1 absolute left-[370px] z-30'>
              {records.map(record => (
                <li key={record?.id} className='bg-gray-50 mb-2 px-2 py-1 hover:bg-gray-200 cursor-pointer' onClick={() => handleRecordSelect(record)}>
                  <span className='text-sm text-black font-normal'>{record?.ref_no}</span>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>

      {files.length > 0 && (
        <div className="file-delete-list-container">
          <h2 className="file-delete-list-title">Uploaded Files</h2>
          <table className="file-delete-table">
            <thead>
              <tr>
                <th>Ref No</th>
                <th className="file-delete-list-header">File Name</th>
                <th className="file-delete-list-header">Actions</th>
              </tr>
            </thead>
            <tbody>
              {files.map((file) => (
                <tr key={file.id}>
                  <td>{file?.ref_no}</td>
                  <td>
                    {files && JSON.parse(file?.job_files)?.map(f => (
                      <li className='list-none font-medium text-gray-600'>{f.fileName}</li>
                    ))}
                  </td>

                  <td className="file-delete-list-actions">
                    <button
                      className="file-delete-button"
                      onClick={() => handleDelete(file.id)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>

  );
};

export default FileDelete;
