import moment from 'moment'
import React from 'react'

const Clearance = ({ clearanceDetails, handleClearanceChange, handleCheckboxChange }) => {
    return (
        <>
            <div className="collapse collapse-arrow bg-base-200 rounded-[5px] mb-2">
                <input type="radio" name="my-accordion-2" />
                <div className="collapse-title text-md font-bold">
                    <label>Cleared on</label>
                </div>
                <div className="collapse-content">
                    <input
                        type="date"
                        name="cleared_on"
                        value={moment(clearanceDetails?.cleared_on).format("YYYY-MM-DD") || null} onChange={handleClearanceChange}
                        className='w-[200px] border border-gray-400 h-10 rounded-[5px] outline-0 px-2'
                    />
                </div>
            </div>
            <div className="collapse collapse-arrow bg-base-200 rounded-[5px] mb-2">
                <input type="radio" name="my-accordion-2" />
                <div className="collapse-title text-md font-bold">
                    <label>Transport details</label>

                </div>
                <div className="collapse-content">
                    <div className='flex space-x-4 items-center mb-2'>
                        <label>Transporter name</label>
                        <input
                            type="text"
                            name="transporter_name"
                            value={clearanceDetails?.transporter_name || ''} onChange={handleClearanceChange}
                            className='w-[300px] border mb-2  border-gray-400 h-8 rounded-md outline-0 px-2'
                        />
                    </div>
                    <div className='flex space-x-4 items-center mb-2'>
                        <label>V No</label>
                        <input
                            type="text"
                            name="v_no"
                            value={clearanceDetails?.v_no || ''} onChange={handleClearanceChange}
                            className='w-[300px] border mb-2  border-gray-400 h-8 rounded-md outline-0 px-2'
                        />
                    </div>
                    <div className='flex space-x-4 items-center mb-2'>
                        <label>H Days</label>
                        <input
                            type="text"
                            name="h_days"
                            value={clearanceDetails?.h_days || ''} onChange={handleClearanceChange}
                            className='w-[300px] border mb-2  border-gray-400 h-8 rounded-md outline-0 px-2'
                        />
                    </div>
                    <div className='flex space-x-4 items-center mb-2'>
                        <label>Amount</label>
                        <input
                            type="text"
                            name="amount"
                            value={clearanceDetails?.amount || ''} onChange={handleClearanceChange}
                            className='w-[300px] border mb-2  border-gray-400 h-8 rounded-md outline-0 px-2'
                        />
                    </div>
                    <div className='flex space-x-4 items-center mb-2'>
                        <label>Advance</label>
                        <input
                            type="text"
                            name="advance"
                            value={clearanceDetails?.advance || ''} onChange={handleClearanceChange}
                            className='w-[300px] border mb-2  border-gray-400 h-8 rounded-md outline-0 px-2'
                        />
                    </div>
                    <div className='flex space-x-4 items-center mb-2'>
                        <label>Balance</label>
                        <input
                            type="text"
                            name="balance"
                            value={clearanceDetails?.balance || ''} onChange={handleClearanceChange}
                            className='w-[300px] border mb-2  border-gray-400 h-8 rounded-md outline-0 px-2'
                        />
                    </div>
                </div>
            </div>
            <div className="collapse collapse-arrow bg-base-200 rounded-[5px] mb-2">
                <input type="radio" name="my-accordion-2" />
                <div className="collapse-title text-md font-bold">
                    Incidental expenses
                </div>
                <div className="collapse-content">
                    <div className='flex space-x-4 items-center mb-2'>
                        <label>Description</label>
                        <input
                            type="text"
                            name="incidental_description"
                            value={clearanceDetails?.incidental_description || ''} onChange={handleClearanceChange}
                            className='w-[300px] border mb-2  border-gray-400 h-8 rounded-md outline-0 px-2'
                        />
                    </div>
                    <div className='flex space-x-4 items-center mb-2'>
                        <label>Amount</label>
                        <input
                            type="text"
                            name="incidental_amount"
                            value={clearanceDetails?.incidental_amount || ''}
                            onChange={handleClearanceChange}
                            className='w-[300px] border mb-2  border-gray-400 h-8 rounded-md outline-0 px-2'
                        />
                    </div>
                    <div className='flex space-x-4 items-center mb-2'>
                        <label>OT Details</label>
                        <input
                            type="checkbox"
                            name="ot_details"
                            checked={clearanceDetails?.ot_details}
                            onChange={handleCheckboxChange}
                            className='w-[300px] border mb-2  border-gray-400 h-4 rounded-md outline-0 px-2'
                        />
                    </div>
                </div>
            </div>
            <div className="collapse collapse-arrow bg-base-200 rounded-[5px] mb-2">
                <input type="radio" name="my-accordion-2" />
                <div className="collapse-title text-md font-bold">
                    Charges
                </div>
                <div className="collapse-content">
                    <div className='flex space-x-4 items-center mb-2'>
                        <label>Charges description</label>
                        <input
                            type="text"
                            name="charges_description"
                            value={clearanceDetails?.charges_description}
                            onChange={handleClearanceChange}
                            className='w-[300px] border mb-2  border-gray-400 h-8 rounded-md outline-0 px-2'
                        />
                    </div>
                    <div className='flex space-x-4 items-center mb-2'>
                        <label>Agent Name</label>
                        <input
                            type="text"
                            name="agent_name"
                            value={clearanceDetails?.agent_name}
                            onChange={handleClearanceChange}
                            className='w-[300px] border mb-2  border-gray-400 h-8 rounded-md outline-0 px-2'
                        />
                    </div>
                    <div className='flex space-x-4 items-center mb-2'>
                        <label>Rcpt</label>
                        <input
                            type="text"
                            name="rcpt"
                            value={clearanceDetails?.rcpt}
                            onChange={handleClearanceChange}
                            className='w-[300px] border mb-2  border-gray-400 h-8 rounded-md outline-0 px-2'
                        />
                    </div>
                    <div className='flex space-x-4 items-center mb-2'>
                        <label>Rcpt date</label>
                        <input
                            type="date"
                            name="rcpt_date"
                            value={moment(clearanceDetails?.rcpt_date).format("YYYY-MM-DD") || null}
                            onChange={handleClearanceChange}
                            className='w-[300px] border mb-2  border-gray-400 h-8 rounded-md outline-0 px-2'
                        />
                    </div>
                    <div className='flex space-x-4 items-center mb-2'>
                        <label>Charges Amount</label>
                        <input
                            type="text"
                            name="charges_amount"
                            value={clearanceDetails?.charges_amount}
                            onChange={handleClearanceChange}
                            className='w-[300px] border mb-2  border-gray-400 h-8 rounded-md outline-0 px-2'
                        />
                    </div>
                    <div className='flex space-x-4 items-center mb-2'>
                        <label>Halting Days</label>
                        <input
                            type="text"
                            name="halting_days"
                            value={clearanceDetails?.halting_days}
                            onChange={handleClearanceChange}
                            className='w-[300px] border mb-2  border-gray-400 h-8 rounded-md outline-0 px-2'
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Clearance