import axios from 'axios';
import React from 'react'

const ShowContainer = ({ containers }) => {
    console.log(containers);

    const handleDelete = async (id) => {
        const BASE_URL = process.env.REACT_APP_BACKEND_URL;
        try {
            const response = await axios.delete(BASE_URL + `import-job/delete-container/${id}`);
            alert(response.data.message);
        } catch (error) {
            console.log(error)
        }
    }
    return (
        <dialog id="my_modal_1" className="absolute max-w-[890px] top-10 left-[300px] px-4 py-4 rounded-md">
            <div className="">
                <h3 className="font-bold text-lg">Container details</h3>
                {containers.length > 0 ? (
                    <div>
                        <table className='table'>
                            <thead>
                                <tr>
                                    <th>Ref No</th>
                                    <th>Seal No#</th>
                                    <th>Container No</th>
                                    <th>Size</th>
                                    <th>Pkgs</th>
                                    <th>Pkgs uom</th>
                                    <th>Gross Wt</th>
                                    <th>Gross wt uom</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {containers.map(ct => (
                                    <tr key={ct.id} className='text-sm'>
                                        <td className='text-sm'>{ct.ref_no}</td>
                                        <td className='text-sm'>{ct.seal_no}</td>
                                        <td className='text-sm'>{ct.container_no}</td>
                                        <td className='text-sm'>{ct.size}</td>
                                        <td className='text-sm'>{ct.pkgs}</td>
                                        <td className='text-sm'>{ct.pkgs_uom}</td>
                                        <td className='text-sm'>{ct.gross_wt}</td>
                                        <td className='text-sm'>{ct.gross_wt_uom}</td>
                                        <td>
                                            <button className='px-2 py-1 text-sm bg-red-600 text-white font-normal' onClick={() => handleDelete(ct.id)}>DELETE</button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                ) : (
                    <div className='w-[900px]'>
                        <h2 className='text-center mt-6 font-semibold text-lg text-gray-600'>NO DATA</h2>
                    </div>
                )}

                <div className="modal-action">
                    <form method="dialog">
                        {/* if there is a button in form, it will close the modal */}
                        <button className="btn">Close</button>
                    </form>
                </div>
            </div>
        </dialog>
    )
}

export default ShowContainer