import moment from 'moment'
import React from 'react'

const Courier = ({ formData, units, handleChange }) => {
    return (
        <div>
            <div className='flex items-center space-x-4'>
                <div>
                    <label>Country of shipment</label>
                    <input
                        type="text"
                        name="country_of_shipment"
                        value={formData?.country_of_shipment}
                        onChange={handleChange}
                        className='w-full h-8 border border-gray-400 rounded-md px-2'
                    />
                </div>
                <div>
                    <label>Port of shippment</label>
                    <input
                        type="text"
                        name="port_of_shipment"
                        value={formData?.port_of_shipment}
                        onChange={handleChange}
                        className='w-full h-8 border border-gray-400 px-2 rounded-md'
                    />
                </div>
            </div>
            <div className='flex items-center space-x-4 my-2'>
                <div>
                    <label>Flight no</label>
                    <input
                        type="text"
                        name="flight_no"
                        value={formData?.flight_no}
                        onChange={handleChange}
                        className='w-full h-8 border border-gray-400 px-2 rounded-md'
                    />
                </div>
                <div>
                    <label>Date</label>
                    <input
                        type="date"
                        name="flight_date"
                        value={moment(formData?.flight_date).format("YYYY-MM-DD") || ''}
                        onChange={handleChange}
                        className='w-full h-8 border border-gray-400 px-2 rounded-md'
                    />
                </div>
                <div>
                    <label>Forwader name</label>
                    <input
                        type="text"
                        name="forwarder"
                        value={formData?.forwarder}
                        onChange={handleChange}
                        className='w-full h-8 border border-gray-400 px-2 rounded-md'
                    />
                </div>
            </div>
            <div className='flex items-center space-x-4 my-2'>
                <div>
                    <label>Air line</label>
                    <input
                        type="text"
                        name="air_line"
                        value={formData?.air_line}
                        onChange={handleChange}
                        className='w-full h-8 border border-gray-400 px-2 rounded-md'
                    />
                </div>
                <div>
                    <label>Rotation no</label>
                    <input
                        type="text"
                        name="rotation"
                        value={formData?.rotation}
                        onChange={handleChange}
                        className='w-full h-8 border border-gray-400 px-2 rounded-md'
                    />
                </div>
                <div>
                    <label>Date</label>
                    <input
                        type="date"
                        name="rotation_date"
                        value={moment(formData?.rotation_date).format("YYYY-MM-DD") || ''}
                        onChange={handleChange}
                        className='w-full h-8 border border-gray-400 px-2 rounded-md'
                    />
                </div>
                <div>
                    <label>Line no</label>
                    <input
                        type="text"
                        name="line_no"
                        value={formData?.line_no}
                        onChange={handleChange}
                        className='w-full h-8 border border-gray-400 px-2 rounded-md'
                    />
                </div>
            </div>
            <div className='flex items-center space-x-4 my-2'>
                <div>
                    <label> M AWB NO</label>
                    <input
                        type="text"
                        name="m_awb_no"
                        value={formData?.m_awb_no}
                        onChange={handleChange}
                        className='w-full h-8 border border-gray-400 px-2 rounded-md'
                    />
                </div>
                <div>
                    <label>Date</label>
                    <input
                        type="date"
                        name="m_awb_date"
                        value={moment(formData?.m_awb_date).format("YYYY-MM-DD") || ''}
                        onChange={handleChange}
                        className='w-full h-8 border border-gray-400 px-2 rounded-md'
                    />
                </div>
                <div>
                    <label> H AWB NO</label>
                    <input
                        type="text"
                        name="h_awb_no"
                        value={formData?.h_awb_no}
                        onChange={handleChange}
                        className='w-full h-8 border border-gray-400 px-2 rounded-md'
                    />
                </div>
                <div>
                    <label>Date</label>
                    <input
                        type="date"
                        name="h_awb_date"
                        value={moment(formData?.h_awb_date).format("YYYY-MM-DD") || ''}
                        onChange={handleChange}
                        className='w-full h-8 border border-gray-400 px-2 rounded-md'
                    />
                </div>
            </div>
            <div className='flex items-center space-x-4 my-2'>
                <div>
                    <label>Net wt</label>
                    <div className='flex items-center space-x-2'>
                        <input
                            type="text"
                            name="net_wt"
                            value={formData?.net_wt || ''}
                            className='px-2 w-[100px]  h-8 rounded-md border border-gray-400'
                            onChange={handleChange}

                        />
                        <select name="net_wt_unit" onChange={handleChange} className='w-full h-8 border border-gray-400 rounded-md px-2'>
                            <option>{formData?.net_wt_unit !== null ? formData?.net_wt_unit : 'Select charges weight'}</option>
                            {units.map(ut => (
                                <option key={ut?.Indexid} value={ut?.uom}>{ut?.uom}</option>
                            ))}
                        </select>
                    </div>
                </div>
                <div>
                    <label>Gross wt</label>
                    <div className='flex items-center space-x-2'>
                        <input
                            type="text"
                            name="gross_wt"
                            value={formData?.gross_wt || ''}
                            className='px-2 w-[100px]  h-8 rounded-md border border-gray-400'
                            onChange={handleChange}

                        />
                        <select name="gross_wt_unit" onChange={handleChange} className='w-full h-8 border border-gray-400 rounded-md px-2'>
                            <option>{formData?.gross_wt_unit !== null ? formData?.gross_wt_unit : 'Select gross weight'}</option>
                            {units.map(ut => (
                                <option key={ut?.Indexid} value={ut?.uom}>{ut?.uom}</option>
                            ))}
                        </select>
                    </div>
                </div>
                <div>
                    <label>Charges wt</label>
                    <div className='flex items-center space-x-2'>
                        <input
                            type="text"
                            name="charges_wt"
                            value={formData?.charges_wt || ''}
                            className='px-2 w-[100px]  h-8 rounded-md border border-gray-400'
                            onChange={handleChange}
                        />
                        <select name="charges_wt_unit" onChange={handleChange} className='w-full h-8 border border-gray-400 rounded-md px-2'>
                            <option>{formData?.charges_wt_unit !== null ? formData?.charges_wt_unit : 'Select charges weight'}</option>
                            {units.map(ut => (
                                <option key={ut?.Indexid} value={ut?.uom}>{ut?.uom}</option>
                            ))}
                        </select>
                    </div>
                </div>
                <div>
                    <label>No of packages</label>
                    <div className='flex items-center space-x-2'>
                        <input
                            type="text"
                            name="num_of_pkgs"
                            value={formData?.num_of_pkgs || ''}
                            className='px-2 w-[100px]  h-8 rounded-md border border-gray-400'
                            onChange={handleChange}
                        />
                        <select name="num_of_pkgs_unit" onChange={handleChange} className='w-full h-8 border border-gray-400 rounded-md px-2'>
                            <option>{formData?.num_of_pkgs_unit !== null ? formData?.num_of_pkgs_unit : 'Select charges weight'}</option>
                            {units.map(ut => (
                                <option key={ut?.Indexid} value={ut?.uom}>{ut?.uom}</option>
                            ))}
                        </select>
                    </div>
                </div>
            </div>
            <div className='flex items-center space-x-4 my-2'>
                <div>
                    <label>STC</label>
                    <div className='flex items-center space-x-2'>
                        <input
                            type="text"
                            name="stc"
                            value={formData?.stc || ''}
                            className='px-2 w-[100px]  h-8 rounded-md border border-gray-400'
                            onChange={handleChange}
                        />
                        <select name="stc_unit" onChange={handleChange} className='w-full h-8 border border-gray-400 rounded-md px-2'>
                            <option>{formData?.stc_unit !== null && formData?.stc_unit !== '' ? formData?.stc_unit : 'Select charges weight'}</option>
                            {units.map(ut => (
                                <option key={ut?.Indexid} value={ut?.uom}>{ut?.uom}</option>
                            ))}
                        </select>
                    </div>
                </div>
                <div>
                    <label>STC 2</label>
                    <div className='flex items-center space-x-2'>
                        <input
                            type="text"
                            name="stc2"
                            value={formData?.stc2 || ''}
                            className='px-2 w-[100px]  h-8 rounded-md border border-gray-400'
                            onChange={handleChange}
                        />
                        <select name="stc2_unit" onChange={handleChange} className='w-full h-8 border border-gray-400 rounded-md px-2'>
                            <option>{formData?.stc2_unit !== null && formData?.stc2_unit !== '' ? formData?.stc2_unit : 'Select charges weight'}</option>
                            {units.map(ut => (
                                <option key={ut?.Indexid} value={ut?.uom}>{ut?.uom}</option>
                            ))}
                        </select>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Courier