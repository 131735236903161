import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react'
import { FaSearch } from 'react-icons/fa'
import { billData, expenseType } from '../constants/constants';

const VoucherDetailsReport = () => {
  const [vouchers, setVouchers] = useState([]);
  const [refNo, setRefNo] = useState('');
  const [records, setRecords] = useState([]);
  const [open, setOpen] = useState(false);
  const [after, setAfter] = useState("");
  const [before, setBefore] = useState("");
  const [exType, setExType] = useState("");
  const [billType, setBillType] = useState("");

  const menuRef = useRef();

  const handleSearch = async () => {
    const BASE_URL = process.env.REACT_APP_BACKEND_URL
    try {
      if (refNo !== '') {
        const response = await axios.get(BASE_URL + `import-job/search?q=${refNo}`);
        setRecords(response.data);
        setOpen(true);
      }
    } catch (error) {
      alert(error?.response.data?.message)
      console.error('Error fetching records:', error);
    }
  };
  const handleRecordSelect = (record) => {
    setRefNo(record?.ref_no);
    setOpen(false);
  }
  useEffect(() => {
    handleSearch()
    const handler = (e) => {
      if (!menuRef.current.contains(e.target)) {
        setOpen(false);
      }
    }
    document.addEventListener('mousedown', handler)
    return () => {
      document.removeEventListener("mousedown", handler);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refNo]);

  const handleFilter = async (e) => {
    e.preventDefault();
    const base_url = process.env.REACT_APP_BACKEND_URL;
    try {
      if (before) {
        let response = await axios.get(base_url + `report/voucher-details-report?before=${before}`);
        setVouchers(response.data.results);
      } else if (after) {
        let response = await axios.get(base_url + `report/voucher-details-report?after=${after}`);
        setVouchers(response.data.results);
      } else if (exType) {
        let response = await axios.get(base_url + `report/voucher-details-report?expenseType=${exType}`);
        setVouchers(response.data.results);
      } else if (billType) {
        let response = await axios.get(base_url + `report/voucher-details-report?billType=${billType}`);
        setVouchers(response.data.results);
      } else if (refNo) {
        let response = await axios.get(base_url + `report/voucher-details-report?refNo=${refNo}`);
        setVouchers(response.data.results);
      }
    } catch (error) {
      console.log(error);
    }
  }
  return (
    <div className='h-screen w-full px-2 mt-4 py-4 bg-white shadow-xl'>
      <div className='flex'>
        <div className='h-[800px] w-[280px] border-r border-gray-200 py-4 px-4'>
          <h1 className='text-xl  mb-4 text-gray-500'>Filter data</h1>
          <form onSubmit={handleFilter}>
            <div className='relative mb-4' ref={menuRef}>
              <div className='w-full mx-auto flex items-center space-x-4 border border-gray-400 px-2 rounded-[5px]'>
                <input
                  type="text"
                  value={refNo || ''}
                  onChange={(e) => setRefNo(e.target.value)}
                  className='w-full  px-2 py-2 rounded-sm outline-0'
                  placeholder='Filter by Ref NO #'
                />
                <FaSearch size='16' />
              </div>
              {(records.length > 0 && open) && (
                <ul className='w-[350px] max-h-[300px] overflow-y-auto shadow-lg bg-white px-2 mt-1 absolute  z-30'>
                  {records.map(record => (
                    <li key={record?.id} className='bg-gray-50 mb-2 px-2 py-1 hover:bg-gray-200 cursor-pointer' onClick={() => handleRecordSelect(record)}>
                      <span className='text-sm text-black font-normal'>{record?.ref_no}</span>
                    </li>
                  ))}
                </ul>
              )}
            </div>
            <div className='mb-4'>
              <label>Filter by after date</label>
              <input
                type="date"
                className='w-full px-2 h-10 border border-gray-300 rounded-md'
                value={after}
                onChange={e => setAfter(e.target.value)}
              />
            </div>
            <div className='mb-4'>
              <label>Filter by Before date</label>
              <input
                type="date"
                className='w-full px-2 h-10 border border-gray-300 rounded-md'
                value={before}
                onChange={e => setBefore(e.target.value)}
              />
            </div>
            <div className='mb-4'>
              <label>Filter by Expenses type</label>
              <select className='w-full px-2 h-10 border border-gray-300 rounded-md' value={exType} onChange={e => setExType(e.target.value)}
              >
                <option>Select expense type</option>
                {expenseType.map(ex => (
                  <option value={ex.name} key={ex.id}>{ex.name}</option>
                ))}
              </select>
            </div>
            <div className='mb-4'>
              <label>Filter by Bill type</label>
              <select className='w-full px-2 h-10 border border-gray-300 rounded-md' value={billType} onChange={e => setBillType(e.target.value)}
              >
                <option>Select bill type</option>
                {billData.map(bill => (
                  <option value={bill.name} key={bill.id}>{bill.name}</option>
                ))}
              </select>
            </div>
            <button className='w-full h-[40px] bg-blue-700 text-white mt-4'>SEARCH</button>
          </form>

        </div>
        <div className='flex-1'>
          {vouchers.length === 0 && <div className='text-center text-gray-700 font-semibold'><h1>NO DATA</h1></div>}
          {vouchers.length > 0 && (
            <table className='table'>
              <thead className='bg-blue-800 text-white'>
                <tr>
                  <th>Date</th>
                  <th>Ref no</th>
                  <th>Party</th>
                  <th>Expenses Type</th>
                  <th>Mode</th>
                  <th>Total amount</th>
                  <th>Prepared by</th>
                  <th>Approve</th>
                  <th>Mode of payment</th>
                  <th>Paid date</th>
                </tr>
              </thead>
              <tbody>
                {vouchers.map(vou => (
                  <tr key={vou.id}>
                    <td>{vou?.date}</td>
                    <td>{vou?.ref_no}</td>
                    <td>{vou?.party}</td>
                    <td>{vou?.expense_type}</td>
                    <td>{vou?.mode}</td>
                    <td>{vou?.total_amt}</td>
                    <td>{vou?.prepared_by}</td>
                    <td>{vou?.approve}</td>
                    <td>{vou?.payment_mode}</td>
                    <td>{vou?.paid_date}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </div>
  )
}

export default VoucherDetailsReport