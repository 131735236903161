import moment from 'moment'
import React from 'react'

const ExBond = ({ formData, handleChange }) => {
    return (
        <div>
            <div className='flex items-center space-x-4 my-4'>
                <div>
                    <label>In Bond B/E No</label>
                    <input
                        type="text"
                        name="inbond_be_no"
                        value={formData?.inbond_be_no}
                        onChange={handleChange}
                        className='w-full h-8 border border-gray-400 px-2 rounded-md'
                    />
                </div>
                <div>
                    <label>Date</label>
                    <input
                        type="Date"
                        name="inbond_date"
                        value={moment(formData?.inbond_date).format("YYYY-MM-DD") || ''}
                        onChange={handleChange}
                        className='w-full h-8 border border-gray-400 px-2 rounded-md'
                    />
                </div>
                <div>
                    <label>Ass value</label>
                    <input
                        type="text"
                        name="ass_value"
                        value={formData?.ass_value}
                        onChange={handleChange}
                        className='w-full h-8 border border-gray-400 px-2 rounded-md'
                    />
                </div>
                <div>
                    <label>Duty amount</label>
                    <input
                        type="text"
                        name="duty_amount"
                        value={formData?.duty_amount}
                        onChange={handleChange}
                        className='w-full h-8 border border-gray-400 px-2 rounded-md'
                    />
                </div>
            </div>
            <div className='flex items-center space-x-4 my-4'>
                <div>
                    <label>Bond No</label>
                    <input
                        type="text"
                        name="bond_no"
                        value={formData?.bond_no}
                        onChange={handleChange}
                        className='w-full h-8 border border-gray-400 px-2 rounded-md'
                    />
                </div>
                <div>
                    <label>Bond Date</label>
                    <input
                        type="Date"
                        name="bond_date"
                        value={moment(formData?.bond_date).format("YYYY-MM-DD") || ''}
                        onChange={handleChange}
                        className='w-full h-8 border border-gray-400 px-2 rounded-md'
                    />
                </div>
            </div>
            <div className='flex items-center space-x-4 my-4'>
                <div>
                    <label>Bond expiry Date</label>
                    <input
                        type="Date"
                        name="bond_expiry_date"
                        value={moment(formData?.bond_expiry_date).format("YYYY-MM-DD") || ''}
                        onChange={handleChange}
                        className='w-full h-8 border border-gray-400 px-2 rounded-md'
                    />
                </div>
                <div>
                    <label>Warehouse</label>
                    <input
                        type="text"
                        name="warehouse"
                        value={formData?.warehouse}
                        onChange={handleChange}
                        className='w-full h-8 border border-gray-400 px-2 rounded-md'
                    />
                </div>
            </div>
        </div>
    )
}

export default ExBond