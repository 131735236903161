import React, { useEffect, useRef, useState } from 'react';
import './styles/JobUpdation.css';
import axios from 'axios';
import { FaSearch } from "react-icons/fa";
import useFetch from '../utils/useFetch';
import { transportMode } from '../constants/constants';
import ShipmentSea from './shipment-details/ShipmentSea';
import ShipmentDetails from './shipment-details/ShipmentDetails';
import ExBond from './shipment-details/ExBond';
import ShipmentRoad from './shipment-details/ShipmentRoad';
import Transfer from './shipment-details/Transfer';
import Courier from './shipment-details/Courier';
import InvoiceModal from './modal/InvoiceModal';
import ShipmentBoe from './shipment-details/ShipmentBoe';
import { Link } from 'react-router-dom';
import FreightModal from './modal/FreightModal';
import moment from 'moment';
import Clearance from './clearance/Clearance';
import ContainerModal from './modal/ContainerModal';
import ShowContainer from './modal/ShowContainer';
const BASE_URL = process.env.REACT_APP_BACKEND_URL;

const ImportJobUpdation = () => {
  const [refNo, setRefNo] = useState('');
  const [open, setOpen] = useState(false);
  const [records, setRecords] = useState([]);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [updateFields, setUpdateFields] = useState({});
  const [updationType, setUpdationType] = useState('');
  const [docDetails, setDocDetails] = useState({
    be_no: '',
    be_date: '',
    value: '',
    duty_amount: '',
    tr6_challan_no: '',
    tr6_challan_date: '',
    goods_rcvd_on: ''
  });
  const [clearanceDetails, setClearanceDetails] = useState({
    cleared_on: null,
    transporter_name: null,
    v_no: null,
    h_days: null,
    amount: null,
    advance: null,
    balance: null,
    incidental_description: null,
    incidental_amount: null,
    ot_details: false,
    charges_description: null,
    agent_name: null,
    rcpt: null,
    rcpt_date: null,
    charges_amount: null,
    halting_days: null
  });
  const [containers, setContainers] = useState([]);
  const [hasDocDetails, setHasDocDetails] = useState(false);
  const [hasClearanceDetails, setHasClearanceDetails] = useState(false);
  const [isInvoicePopupOpen, setIsInvoicePopupOpen] = useState(false);
  const [isFreightPopupOpen, setIsFreightPopupOpen] = useState(false);
  const [isTransportPopupOpen, setIsTransportPopupOpen] = useState(false);
  const [isIncidentalPopupOpen, setIsIncidentalPopupOpen] = useState(false);
  const [isChargesPopupOpen, setIsChargesPopupOpen] = useState(false);
  const [errors, setErrors] = useState({});
  const { currencies, units } = useFetch();
  const menuRef = useRef();

  const getDocDetails = async () => {
    try {
      const result = await axios.get(BASE_URL + `import-job/doc/details/${refNo}`);
      console.log('doc details', result.data);
      if (result.data !== "") {
        setDocDetails({
          be_no: result.data?.be_no,
          be_date: result.data?.be_date,
          value: result.data?.value,
          duty_amount: result.data.duty_amount,
          tr6_challan_no: result.data?.tr6_challan_no,
          tr6_challan_date: result.data.tr6_challan_date,

        })
        setHasDocDetails(true);
      }

    } catch (error) {
      console.log(error);
    }
  }
  const getClearanceDetails = async () => {
    try {
      const result = await axios.get(BASE_URL + `import-job/get-clearance-details/${refNo}`);
      console.log(result.data);
      if (result.data !== null) {
        setClearanceDetails({
          ref_no: result?.data?.ref_no || refNo,
          cleared_on: result.data?.cleared_on || null,
          transporter_name: result.data?.transporter_name || null,
          v_no: result.data?.v_no || null,
          h_days: result.data?.h_days || null,
          amount: result.data?.amount || null,
          advance: result.data?.advance || null,
          balance: result.data?.balance || null,
          incidental_description: result.data?.incidental_description || null,
          incidental_amount: result.data?.incidental_amount || null,
          ot_details: result.data?.ot_details || null,
          charges_description: result.data?.charges_description || null,
          agent_name: result.data?.agent_name || null,
          rcpt: result.data?.rcpt || null,
          rcpt_date: result.data?.rcpt_date || null,
          charges_amount: result.data?.charges_amount || null,
          halting_days: result.data?.halting_days || null
        })
        setHasClearanceDetails(true)
      }

    } catch (error) {
      console.log(error);
    }
  }
  // Get container details
  const listContainers = async () => {
    try {
      const response = await axios.get(BASE_URL + `import-job/list/container/${refNo}`);
      setContainers(response.data);
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    handleSearch()
    getDocDetails();
    getClearanceDetails();
    if (refNo) {
      listContainers();

    }
    const handler = (e) => {
      if (!menuRef.current.contains(e.target)) {
        setOpen(false);
      }
    }
    document.addEventListener('mousedown', handler)
    return () => {
      document.removeEventListener("mousedown", handler);
    }
  }, [refNo, selectedRecord?.ref_no])

  const handleSearch = async () => {
    try {
      if (refNo !== '') {
        const response = await axios.get(BASE_URL + `import-job/search?q=${refNo}`);
        setRecords(response.data);
      }
    } catch (error) {
      console.error('Error fetching records:', error);
    }
  };

  const formatDateForInput = (dateString) => {
    if (!dateString) return '';

    const date = new Date(dateString);

    if (isNaN(date.getTime())) return '';

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const handleRecordSelect = (record) => {
    setRefNo(record?.ref_no);
    setSelectedRecord(record);
    setOpen(false);
    setUpdateFields(record);
  };

  const handleFieldChange = (e) => {
    const { name, value } = e.target;
    setUpdateFields(prev => ({ ...prev, [name]: value }));
  };

  // const handleInvoiceChange = (e) => {
  //   const { name, value } = e.target;
  //   setInvoiceDetails(prev => ({ ...prev, [name]: value }));
  // };
  const isValidContainerNo = (container_no) => {
    let pattern = /^[A-Z0-9]{4}[0-9]{7}$/
    return pattern.test(container_no);
  }
  const isValidTotalContainer = (total_container) => {
    let pattern = /^(?:[1-9]\d*)$/;
    return pattern.test(total_container);
  }
  const validateForm = () => {
    let newError = {};

    if (updateFields?.date === null) {
      newError.date = ' Date is Required!'
    }
    if (updateFields.importer_name === null) {
      newError.importer_name = 'Importer name is required!.'
    }
    if (updateFields.consignor_name === null) {
      newError.importer_name = 'Consignor name is required!.'
    }
    if (updateFields.trans_mode === 'Sea' && updateFields?.fcl_lcl === 'LCL') {
      if (updateFields?.container_no === '') {
        newError.container_no = 'Container no is required!'
      } else if (!isValidContainerNo(updateFields?.container_no)) {
        newError.container_no = 'Container no should have 4 alphanumeric Uppercase characters and 7 numbers!'
      }
    }

    setErrors(newError);
    return Object.keys(newError).length === 0;
  }
  const handleClearanceChange = (e) => {
    const { name, value } = e.target;
    setClearanceDetails(prev => ({ ...prev, [name]: value }));
  };

  const handleDocsChange = (e) => {
    const { name, value } = e.target;
    setDocDetails(prev => ({ ...prev, [name]: value }))
  }
  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setClearanceDetails(prev => ({ ...prev, [name]: checked }));
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    const isValid = validateForm();
    if (isValid) {
      try {
        await axios.put(BASE_URL + `import-job/update`, updateFields);

        if (updationType === 'Document Details') {
          if (hasDocDetails) {
            await axios.put(BASE_URL + `import-job/update-document-details`, {
              ref_no: refNo,
              ...docDetails
            });
          } else {
            await axios.post(BASE_URL + `import-job/save-document-details`, {
              ref_no: refNo,
              ...docDetails
            });
          }
        } else if (updationType === 'Clearance') {
          if (hasClearanceDetails) {
            await axios.put(BASE_URL + `import-job/update-clearance-details`, {
              ref_no: refNo,
              ...clearanceDetails
            });
          } else {
            await axios.post(BASE_URL + `import-job/save-clearance-details`, {
              ref_no: refNo,
              ...clearanceDetails
            });
          }
        }

        alert('Record updated successfully');
      } catch (error) {
        console.error('Error updating record:', error);
      }
    } else {
      // alert('Validation failed!.')
      console.log('validation failed')
    }

  };
  console.log('clearance', hasClearanceDetails, clearanceDetails, refNo)
  return (
    <div className='w-full max-h-full bg-white px-8'>
      <h1 className='text-center my-6 font-semibold text-2xl'>Job Updation</h1>
      <div className='w-full  flex items-center justify-between relative px-4 ' ref={menuRef}>
        <div className='w-[300px]'>
          <label>Search Ref's no</label>
          <div className='w-[350px] flex items-center space-x-4 border border-gray-400 px-2 rounded-sm'>
            <input
              type="text"
              value={refNo || ''}
              onChange={(e) => setRefNo(e.target.value)}
              className='w-full  px-2 py-1 rounded-sm outline-0'
              onClick={() => setOpen(!open)}
              placeholder='Search reference no'
            />
            <span className='text-blue-600 font-bold text-sm'>{selectedRecord?.ref_no}</span>
            <FaSearch size='21' />
          </div>
          {open && refNo !== '' && (
            <ul className='my-4 max-h-[400px] w-[300px] mx-auto bg-white shadow-lg px-4 py-2 rounded-sm absolute top-[50px] overflow-y-auto z-40'>
              {records.map(record => (
                <li key={record?.id} className='bg-gray-100 mb-2 py-[10px] px-2 rounded-sm text-xs font-semibold flex items-center  space-x-8 cursor-pointer' onClick={() => handleRecordSelect(record)}>
                  <span>{record?.ref_no}</span>
                  <span>{record?.importer_name}</span>
                </li>
              ))}
            </ul>
          )}
        </div>
        <div className='w-[200px] my-4'>
          <label>Updation</label>
          <select onChange={(e) => setUpdationType(e.target.value)} className='border border-gray-400 w-full h-8 rounded-md px-2 outline-0'>
            <option value="">Select</option>
            <option value="Document Details">Document Details</option>
            <option value="Clearance">Clearance</option>
          </select>
        </div>
      </div>
      <div className='h-[1px] w-full bg-gray-100 mt-4'></div>
      <form onSubmit={handleUpdate}>
        {/* Header 1  */}
        <div className='flex items-center justify-between mt-2'>
          <div>
            <label>Service type:</label>
            <select
              name="service_type"
              value={updateFields?.service_type}
              onChange={handleFieldChange}
              className=' rounded-md h-8 border border-gray-300  outline-0 w-full'
            >
              <option value={updateFields?.service_type}>{updateFields?.service_type || 'Select'}</option>
              <option value="Imports">Imports</option>
              <option value="Exports">Exports</option>
            </select>
          </div>
          <div>
            <label>IMP/EXP NO</label>
            <input type="type"
              name="imp_exp_no"
              value={updateFields.import_no ? updateFields?.import_no : updateFields?.export_no} onChange={handleFieldChange}
              className='w-full border border-gray-400 h-8 rounded-md outline-0 px-2'

            />
          </div>
          <div>
            <label>Imp/Exp date</label>
            <input type="date"
              name="date"
              value={moment(updateFields?.date).format("YYYY-MM-DD") || ''} onChange={handleFieldChange}
              className='w-full border border-gray-400 h-8 rounded-md outline-0 px-2'

            />
          </div>
          <div>
            <label>Job#</label>
            <input
              type="text"
              name="job_no"
              value={updateFields.job_no || ''} onChange={handleFieldChange}
              className='w-full border border-gray-400 h-8 rounded-md outline-0 px-2'
            />
          </div>
          <div>
            <label>Job date</label>
            <input
              type="date"
              name="job_date"
              value={moment(updateFields?.job_date).format("YYYY-MM-DD") || ''} onChange={handleFieldChange}
              className='w-full border border-gray-400 h-8 rounded-md outline-0 px-2'
            />
          </div>
        </div>
        {/* END header 1 */}
        <div className='h-[1px] w-full bg-gray-100 mt-4'></div>
        {/* Header 2 */}
        <div className='flex items-center justify-between my-4'>
          <div>
            <label>Trans Mode:</label>
            <select
              name="trans_mode"
              onChange={handleFieldChange}
              className='w-[200px] rounded-md h-8 border border-gray-300 ml-2 text-sm outline-0'
            >
              <option>{updateFields?.trans_mode}</option>
              {transportMode.map((el, i) => (
                <option key={i} value={el.name}>{el.name}</option>
              ))}
            </select>
          </div>
          <div>
            <label>Branch</label>
            <input
              type="text"
              name="branch"
              value={updateFields.branch || ''} onChange={handleFieldChange}
              className='w-[200px] border ml-2 border-gray-400 h-8 rounded-md outline-0 px-2'
            />
          </div>
          {updateFields.trans_mode === 'Sea' && (
            <div>
              <label>FCL/LCL</label>
              <select
                name="fcl_lcl"
                onChange={handleFieldChange}
                className='w-[200px] rounded-md h-8 border border-gray-300 ml-2 text-sm outline-0'>
                <option>{updateFields?.fcl_lcl ? updateFields?.fcl_lcl : 'Select'}</option>
                <option value="FCL">FCL</option>
                <option value="LCL">LCL</option>
              </select>
            </div>
          )}

          <div>
            <label>{updateFields?.service_type === null && 'B/E TYPE'}</label>
            <label>{updateFields?.service_type === 'Imports' && 'B/E TYPE'}</label>
            <label>{updateFields?.service_type === 'Exports' && 'S/B TYPE'}</label>
            {updateFields?.service_type === '' && (
              <select name="be_type" onChange={handleFieldChange} className='w-[200px] border border-gray-300 h-[35px] ml-2' >
                <option>Select</option>
                <option value="SEZ">SEZ</option>
                <option value="HOME">HOME</option>
                <option value="IN BOND">IN BOND</option>
                <option value="EX BOND">EX BOND</option>
                <option value="DTA">DTA</option>
                <option value="BOE">BOE</option>
                <option value="TRANSFER">TRANSFER</option>
              </select>
            )}
            {/* Be sb type */}
            {/* imports */}
            {updateFields?.service_type === 'Imports' && (
              <select name="be_type" onChange={handleFieldChange} className='w-[200px] border border-gray-300 h-[35px] ml-2'>
                <option>{updateFields?.be_type ? updateFields?.be_type : 'Select be type'}</option>
                {(updateFields?.trans_mode === 'Air') && (
                  <>
                    <option value="SEZ">SEZ</option>
                    <option value="HOME">HOME</option>
                    <option value="IN BOND">IN BOND</option>
                    <option value="EX BOND">EX BOND</option>
                  </>
                )}
                {(updateFields?.trans_mode === 'Sea') && (
                  <>
                    <option value="SEZ">SEZ</option>
                    <option value="HOME">HOME</option>
                    <option value="IN BOND">IN BOND</option>
                    <option value="EX BOND">EX BOND</option>
                  </>
                )}
                {(updateFields?.trans_mode === 'Road') && (
                  <>
                    <option value="DTA">DTA</option>
                    <option value="TRANSFER">TRANSFER</option>

                  </>
                )}
                {(updateFields?.trans_mode === 'Courier') && (
                  <>
                    <option value="SEZ">SEZ</option>
                    <option value="HOME">HOME</option>

                  </>
                )}
              </select>
            )}
            {/* end of imports */}
            {/* exports */}
            {updateFields?.service_type === 'Exports' && (
              <select name="sb_type" onChange={handleFieldChange} className='w-[200px] border border-gray-300 h-[35px] ml-2'>
                <option>{updateFields?.sb_type ? updateFields?.sb_type : 'Select be type'}</option>
                {(updateFields?.trans_mode === 'Air') && (
                  <>
                    <option value="SEZ">SEZ</option>
                    <option value="HOME">HOME</option>
                  </>
                )}
                {(updateFields?.trans_mode === 'Sea') && (
                  <>
                    <option value="SEZ">SEZ</option>
                    <option value="HOME">HOME</option>
                  </>
                )}
                {(updateFields?.trans_mode === 'Road') && (
                  <>
                    <option value="BOE">BOE</option>
                    <option value="TRANSFER">TRANSFER</option>

                  </>
                )}
                {(updateFields?.trans_mode === 'Courier') && (
                  <>
                    <option value="SEZ">SEZ</option>
                    <option value="HOME">HOME</option>

                  </>
                )}
              </select>
            )}
            {/* End of exports */}
          </div>
        </div>
        <div className='h-[1px] w-full bg-gray-100 mt-4'></div>
        {/* header 3 */}
        <div className='mt-2 flex items-center justify-between'>
          <div>
            <label>Importer/Exporter</label>
            <input
              type="text"
              name="importer_name"
              value={updateFields.importer_name || ''} onChange={handleFieldChange}
              className='w-full border  border-gray-400 h-8 rounded-md outline-0 px-2'
            />
          </div>
          <div>
            <label>	CONSIGNER/CONSIGNEE</label>
            <input
              type="text"
              name="consignor_name"
              value={updateFields.consignor_name || ''} onChange={handleFieldChange}
              className='w-full border  border-gray-400 h-8 rounded-md outline-0 px-2'
            />
          </div>
        </div>
        <div className='h-[1px] w-full bg-gray-100 mt-4'></div>
        {/* shippment details */}
        <div className='my-4'>
          <h1 className='font-bold text-lg'>Shipment details</h1>
          {updateFields?.service_type === null && updateFields?.trans_mode === null && (
            <ShipmentSea
              formData={updateFields}
              units={units}
              handleChange={handleFieldChange}
            />
          )}
          {/* Air */}
          {(updateFields?.service_type === 'Imports' && updateFields?.trans_mode === 'Air' && updateFields?.be_type === 'SEZ') && (
            <ShipmentDetails
              formData={updateFields}
              units={units}
              handleChange={handleFieldChange}
            />
          )}
          {(updateFields?.service_type === 'Imports' && updateFields?.trans_mode === 'Air' && updateFields?.be_type === 'HOME') && (
            <ShipmentDetails
              formData={updateFields}
              units={units}
              handleChange={handleFieldChange}
            />
          )}
          {(updateFields?.service_type === 'Imports' && updateFields?.trans_mode === 'Air' && updateFields?.be_type === 'IN BOND') && (
            <ShipmentDetails
              formData={updateFields}
              units={units}
              handleChange={handleFieldChange}
            />
          )}
          {(updateFields?.service_type === 'Imports' && updateFields?.trans_mode === 'Air' && updateFields?.be_type === 'EX BOND') && (
            <ExBond
              formData={updateFields}
              handleChange={handleFieldChange}
            />
          )}
          {/* sea */}
          {(updateFields?.service_type === 'Imports' && updateFields?.trans_mode === 'Sea' && updateFields?.be_type === 'SEZ') && (
            <ShipmentSea
              formData={updateFields}
              units={units}
              handleChange={handleFieldChange}
              errors={errors}
            />
          )}
          {(updateFields?.service_type === 'Imports' && updateFields?.trans_mode === 'Sea' && updateFields?.be_type === 'HOME') && (
            <ShipmentSea
              formData={updateFields}
              units={units}
              handleChange={handleFieldChange}
              errors={errors}

            />
          )}
          {(updateFields?.service_type === 'Imports' && updateFields?.trans_mode === 'Sea' && updateFields?.be_type === 'IN BOND') && (
            <ShipmentSea
              formData={updateFields}
              units={units}
              handleChange={handleFieldChange}
              errors={errors}

            />
          )}
          {(updateFields?.service_type === 'Imports' && updateFields?.trans_mode === 'Sea' && updateFields?.be_type === 'EX BOND') && (
            <ExBond
              formData={updateFields}
              handleChange={handleFieldChange}
            />
          )}
          {/* road */}
          {(updateFields?.service_type === 'Imports' && updateFields?.trans_mode === 'Road' && updateFields?.be_type === 'DTA') && (
            <ShipmentRoad
              formData={updateFields}
              units={units}
              handleChange={handleFieldChange}
            />
          )}
          {(updateFields?.service_type === 'Imports' && updateFields?.trans_mode === 'Road' && updateFields?.be_type === 'TRANSFER') && (
            <Transfer
              formData={updateFields}
              units={units}
              handleChange={handleFieldChange}
              serviceType={updateFields?.service_type}
            />
          )}
          {/* Courier */}
          {(updateFields?.service_type === 'Imports' && updateFields?.trans_mode === 'Courier' && updateFields?.be_type === 'SEZ') && (
            <Courier
              formData={updateFields}
              units={units}
              handleChange={handleFieldChange}
            />
          )}
          {(updateFields?.service_type === 'Imports' && updateFields?.trans_mode === 'Courier' && updateFields?.be_type === 'HOME') && (
            <Courier
              formData={updateFields}
              units={units}
              handleChange={handleFieldChange}
            />
          )}
          {/* Export  Air */}
          {(updateFields?.service_type === 'Exports' && updateFields?.trans_mode === 'Air' && updateFields?.sb_type === 'SEZ') && (
            <ShipmentDetails
              formData={updateFields}
              units={units}
              handleChange={handleFieldChange}
            />
          )}
          {(updateFields?.service_type === 'Exports' && updateFields?.trans_mode === 'Air' && updateFields?.sb_type === 'HOME') && (
            <ShipmentDetails
              formData={updateFields}
              units={units}
              handleChange={handleFieldChange}
            />
          )}
          {/* Export Sea */}
          {(updateFields?.service_type === 'Exports' && updateFields?.trans_mode === 'Sea' && updateFields?.sb_type === 'SEZ') && (
            <ShipmentSea
              formData={updateFields}
              units={units}
              handleChange={handleFieldChange}
              errors={errors}

            />
          )}
          {(updateFields?.service_type === 'Exports' && updateFields?.trans_mode === 'Sea' && updateFields?.sb_type === 'HOME') && (
            <ShipmentSea
              formData={updateFields}
              units={units}
              handleChange={handleFieldChange}
              errors={errors}

            />
          )}
          {/* Exports Road */}
          {(updateFields?.service_type === 'Exports' && updateFields?.trans_mode === 'Road' && updateFields?.sb_type === 'BOE') && (
            <ShipmentBoe
              formData={updateFields}
              units={units}
              handleChange={handleFieldChange}
            />
          )}
          {(updateFields?.service_type === 'Exports' && updateFields?.trans_mode === 'Road' && updateFields?.sb_type === 'TRANSFER') && (
            <Transfer
              formData={updateFields}
              units={units}
              handleChange={handleFieldChange}
              serviceType={updateFields?.service_type}
            />
          )}
          {/* Exports Courier */}
          {(updateFields?.service_type === 'Exports' && updateFields?.trans_mode === 'Courier' && updateFields?.sb_type === 'SEZ') && (
            <Courier
              formData={updateFields}
              units={units}
              handleChange={handleFieldChange}
            />
          )}
          {(updateFields?.service_type === 'Exports' && updateFields?.trans_mode === 'Courier' && updateFields?.sb_type === 'HOME') && (
            <Courier
              formData={updateFields}
              units={units}
              handleChange={handleFieldChange}
            />
          )}
        </div>
        {/* End  of shipment details */}
        <div className='w-full   my-6'>
          <h2 className='text-left text-lg font-semibold'>Invoice details</h2>
          <button type="button" className="px-4 py-1 bg-blue-500 text-white" onClick={() => document.getElementById('my_modal_4').showModal()}>Add Here</button>
          {updateFields?.trans_mode === 'Sea' && updateFields?.fcl_lcl === 'FCL' && (
            <div className='mt-4'>
              <button type="button" className='px-4 py-2 bg-lime-500 text-white text-md' onClick={() => document.getElementById('my_modal_1').showModal()}>Container Details</button>
              <ShowContainer
                containers={containers}
              />
            </div>
          )}
          <InvoiceModal
            formData={updateFields}
            units={units}
            currencies={currencies}
            handleChange={handleFieldChange}
          />
        </div>
        {/* Document type */}
        {updationType === 'Document Details' && (
          <div className='flex space-x-6'>
            <div>
              <div>
                <label>BE NO</label>
                <input
                  type="text"
                  name="be_no"
                  value={docDetails.be_no || ''} onChange={handleDocsChange}
                  className='w-full border mb-2  border-gray-400 h-8 rounded-md outline-0 px-2'
                />
              </div>
              <div>
                <label>Value</label>
                <input
                  type="text"
                  name="value"
                  value={docDetails?.value || ''} onChange={handleDocsChange}
                  className='w-full border mb-2  border-gray-400 h-8 rounded-md outline-0 px-2'
                />
              </div>
              <div>
                <label>Tr 6 challan No</label>
                <input
                  type="text"
                  name="tr6_challan_no"
                  value={docDetails?.tr6_challan_no || ''} onChange={handleDocsChange}
                  className='w-full border mb-2  border-gray-400 h-8 rounded-md outline-0 px-2'
                />
              </div>
            </div>
            <div>
              <div>
                <label>Date</label>
                <input
                  type="date"
                  name="be_date"
                  value={moment(docDetails?.be_date).format("YYYY-MM-DD") || null} onChange={handleDocsChange}
                  className='w-full border mb-2  border-gray-400 h-8 rounded-md outline-0 px-2'
                />
              </div>
              <div>
                <label>Duty amount</label>
                <input
                  type="text"
                  name="duty_amount"
                  value={docDetails?.duty_amount || ''} onChange={handleDocsChange}
                  className='w-full border mb-2  border-gray-400 h-8 rounded-md outline-0 px-2'
                />
              </div>
              <div>
                <label>Challan Date</label>
                <input
                  type="date"
                  name="tr6_challan_date"
                  value={moment(docDetails?.tr6_challan_date).format("YYYY-MM-DD") || ''} onChange={handleDocsChange}
                  className='w-full border mb-2  border-gray-400 h-8 rounded-md outline-0 px-2'
                />
              </div>
            </div>
          </div>
        )}
        {/* clearance */}
        {updationType === 'Clearance' &&
          <Clearance
            clearanceDetails={clearanceDetails}
            handleClearanceChange={handleClearanceChange}
            handleCheckboxChange={handleCheckboxChange}
          />}
        {/* end of clearance */}

        {/* End of doc type */}
        <div className='w-full text-center mt-6  pb-8'>
          <button type="submit" className='px-4 py-2 outline-0 bg-blue-500  text-sm text-white w-[200px]'>SUBMIT</button>
        </div>
      </form>
    </div>
  )
};

export default ImportJobUpdation;
