import React, { useState, useEffect, useContext, useRef } from 'react';
import axios from 'axios';
import './styles/VoucherEntry.css';  // Ensure you create a CSS file to style this component
import { AuthContext } from '../AuthContext';
import { expenseType } from '../constants/constants';
import moment from 'moment';
import useFetch from '../utils/useFetch';
import { FaSearch } from "react-icons/fa";

const BASE_URL = process.env.REACT_APP_BACKEND_URL;

const VoucherEntry = () => {
  const [open, setOpen] = useState(false);
  const [exp, setExp] = useState("");
  const [expAmount, setExpAmount] = useState("");
  const [voucherNumber, setVoucherNumber] = useState(1); // Start with 00001
  const [selectedType, setSelectedType] = useState('');
  const { units } = useFetch();
  const { user } = useContext(AuthContext);
  const username = user.username;
  const [formData, setFormData] = useState({
    voucher_number: '',
    date: '',
    expense_type: '',
    ref_no: "",
    service_type: "",
    mode: "",
    bill_type: "",
    party: "",
    size_20: "",
    size_40: "",
    total_container: "",
    num_of_pkgs: "",
    num_of_pkgs_unit: "",
    num_of_pkgs1: "",
    num_of_pkgs1_unit: "",
    num_of_pkgs2: "",
    num_of_pkgs2_unit: "",
    gross_wt: "",
    gross_wt_unit: "",
    expenses: [],
    total_amt: "",
    payment_mode: "",
    cheque: "",
    payment_date: '',
    in_favour_of: '',
    bank_name: '',
    remarks: '',
    prepared_by: username || '',
    year: ''
  });
  const [importJobData, setImportJobData] = useState([]);
  const [transporterData, setTransporterData] = useState([]);
  const [names, setNames] = useState([]);


  useEffect(() => {
    axios.get(process.env.REACT_APP_BACKEND_URL + 'latest-voucher')
      .then(response => {
        const latestVoucher = response.data.latestVoucher;
        setVoucherNumber(latestVoucher + 1); // Set next voucher number
      })
      .catch(error => console.error('Error fetching latest voucher number:', error));
    // Fetch data from import_job and transporter_master tables

    axios.get(process.env.REACT_APP_BACKEND_URL + 'transporter-master')
      .then(response => setTransporterData(response.data))
      .catch(error => console.error('Error fetching transporter data:', error));

    axios.get(BASE_URL + 'names')
      .then(response => setNames(response.data))
      .catch(error => console.error('Error fetching names:', error));
  }, []);
  const menuRef = useRef();

  useEffect(() => {
    handleFetchDetails()
    const getJobData = async () => {
      try {
        const response = await axios.get(BASE_URL + `import-job/search?q=${formData?.ref_no}`);
        setImportJobData(response.data);
        setOpen(true)
      } catch (error) {
        alert('Error getting job data')
      }
    }
    getJobData()
    const handler = (e) => {
      if (!menuRef.current.contains(e.target)) {
        setOpen(false);
      }
    }
    document.addEventListener('mousedown', handler)
    return () => {
      document.removeEventListener("mousedown", handler);
    }
  }, [formData?.ref_no])
  const handleTypeChange = (event) => {
    setSelectedType(event.target.value);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };
  const handleFetchDetails = () => {
    if (formData.ref_no) {
      axios.get(BASE_URL + `voucher-entry/${formData.ref_no}`)
        .then(response => {
          console.log(response.data);
          const jobDetails = response.data;
          setFormData(prevData => ({
            ...prevData,
            service_type: jobDetails.service_type,
            mode: jobDetails.trans_mode,
            party: jobDetails.importer_name,
            num_of_pkgs: jobDetails?.num_of_pkgs,
            num_of_pkgs_unit: jobDetails?.num_of_pkgs_unit
          }));
        })
        .catch(error => console.error('Error fetching import job details:', error));
    }
  };



  const handleExpAmount = () => {

    const data = { id: Date.now(), exp, expAmount };
    setFormData((prevState) => {
      const updatedArray = [...prevState.expenses, data];
      return {
        ...prevState,
        expenses: updatedArray
      }
    })
    setExp("");
    setExpAmount("")
  }

  const handleSave = (e) => {
    e.preventDefault();
    console.log(formData);
    axios.post(process.env.REACT_APP_BACKEND_URL + 'voucher-entry', {
      ...formData
    })
      .then(() => {
        alert('Voucher saved successfully');
        axios.get(process.env.REACT_APP_BACKEND_URL + 'latest-voucher')
          .then(response => {
            const latestVoucher = response.data.latestVoucher;
            setVoucherNumber(latestVoucher + 1); // Update voucher number
          })
          .catch(error => console.error('Error fetching latest voucher number after saving:', error));
        setFormData({  // Reset form after saving
          date: '',
          expenseType: '',
          voucher_head: '',
          refNo: '',
          serviceType: '',
          mode: '',
          party: '',
          netWeight: '',
          grossWeight: '',
          expenses: '',
          expensesAmt: '',
          totalAmt: '',
          paymentMode: 'cash',
          cheque: '',
          paymentDate: '',
          inFavourOf: '',
          bankName: '',
          remarks: '',
        });
      })
      .catch(error => console.error('Error saving voucher:', error));
  };

  const handleDelete = (id) => {
    setFormData((prevState) => {
      const upState = prevState.expenses.filter((_, i) => i !== id);
      return {
        ...prevState,
        expenses: upState
      }
    })
  }
  const handleSelectedRef = (job) => {
    setFormData({ ...formData, ref_no: job?.ref_no })
    setOpen(false)
  }

  const handleFocus = () => {
    setOpen(true);
  }
  const handleBlur = () => {
    setTimeout(() => {

      setOpen(false)

    }, 1000)
  }
  return (
    <div className="voucher-entry">
      <div className="voucher-entry-title">
        <h1>Voucher Entry</h1></div>
      <div className="voucher-entry-type">
        <label>Select Type: </label>
        <select onChange={handleTypeChange} value={selectedType}>
          <option value="">Select Type</option>
          <option value="general">General</option>
          <option value="accounts">Accounts</option>
        </select>
      </div>
      <form onSubmit={handleSave}>
        <div className='flex gap-x-4'>
          {/* left side */}
          <div className='bg-white h-full px-2 py-2 basis-[55%]'>
            <div className='mb-4'>
              <label>Voucher no</label>
              <input
                type="text"
                name="voucher_number"
                className='h-8 border border-gray-400 rounded-md px-2 w-full'
                placeholder='Voucher no'
                value={formData?.voucher_number || ''}
                onChange={handleInputChange}
              />
            </div>
            <div className='mb-4'>
              <label>Voucher Date</label>
              <input
                type="date"
                name="date"
                className='h-8 border border-gray-400 rounded-md px-2 w-full'
                placeholder='Date'
                value={formData?.date || ''}
                onChange={handleInputChange}
              />
            </div>
            <div className='mb-4'>
              <label>Year</label>
              <select name="year" onChange={handleInputChange} className='h-8 border border-gray-400 rounded-md px-2 w-full'
              >
                <option>Select a year</option>
                <option value="2023-2024">2023-2024</option>
                <option value="2024-2025">2024-2025</option>
              </select>
            </div>
            {selectedType === 'general' && (
              <div className='mb-4'>
                <label>Voucher head</label>
                <input
                  type="date"
                  name="date"
                  className='h-8 border border-gray-400 rounded-md px-2 w-full'
                  placeholder='Date'
                  value={formData?.date || ''}
                  onChange={handleInputChange}
                />
              </div>
            )}
            {/* expenses type */}
            <div className='flex items-center space-x-2 mb-4'>
              <div>
                <input
                  type="text"
                  className='h-8 border border-gray-400 rounded-md px-2 w-full'
                  placeholder='Expense'
                  value={exp || ''}
                  onChange={(e) => setExp(e.target.value)}
                />
              </div>
              <div>
                <input
                  type="text"
                  className='h-8 border border-gray-400 rounded-md px-2 w-full'
                  placeholder='Expense amount'
                  value={expAmount || ''}
                  onChange={(e) => setExpAmount(e.target.value)}
                />
              </div>
              <button type="button" onClick={handleExpAmount} className='h-8 px-2 text-white bg-blue-700 text-sm w-[140px]'>Add expenses</button>
            </div>
            {formData?.expenses.length > 0 && (
              <div className='w-[440px]'>
                <table className='table'>
                  <thead>
                    <tr>
                      <th>Expenses</th>
                      <th>Amount</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {formData?.expenses.map((ex, i) => (
                      <tr key={ex.id}>
                        <td>{ex?.exp}</td>
                        <td>{ex.expAmount}</td>
                        <td>
                          <button type="button" onClick={() => handleDelete(i)} className='h-8 px-2 text-white bg-red-700 text-xs'>DELETE</button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
            {selectedType === 'accounts' && (
              <div>
                <div className='mb-4'>
                  <label>Bill type</label>
                  <input
                    type="text"
                    name="bill_type"
                    value={formData?.bill_type}
                    onChange={handleInputChange}
                    className='h-8 border border-gray-400 rounded-md px-2 w-full'
                    placeholder='Bill type'
                  />
                </div>
                <div className='mb-4'>
                  <label>Service type</label>
                  <input
                    type="text"
                    name="service_type"
                    value={formData?.service_type}
                    onChange={handleInputChange}
                    className='h-8 border border-gray-400 rounded-md px-2 w-full'
                    placeholder='Service type'
                  />
                </div>
                <div className='mb-4'>
                  <label>Size 20</label>
                  <input
                    type="text"
                    name="size_20"
                    value={formData?.size_20}
                    onChange={handleInputChange}
                    className='h-8 border border-gray-400 rounded-md px-2 w-full'
                    placeholder='Size 20'
                  />
                </div>
                <div className='mb-4'>
                  <label>Size 40</label>
                  <input
                    type="text"
                    name="size_40"
                    value={formData?.size_40}
                    onChange={handleInputChange}
                    className='h-8 border border-gray-400 rounded-md px-2 w-full'
                    placeholder='Size 40'
                  />
                </div>
              </div>
            )}
            <div className='mb-4'>
              <label>Total amount</label>
              <input
                type="text"
                name="total_amt"
                value={formData?.total_amt}
                onChange={handleInputChange}
                className='h-8 border border-gray-400 rounded-md px-2 w-full'
                placeholder='Total amount'
              />
            </div>
            <div className='mb-4'>
              <label>Prepared by</label>
              <input
                type="text"
                name="prepared_by"
                value={formData?.prepared_by}
                onChange={handleInputChange}
                className='h-8 border border-gray-400 rounded-md px-2 w-full'
                placeholder='Prepared By'
              />
            </div>
            <div className='mb-4'>
              <label>Payment mode</label>
              <select name="payment_mode" onChange={handleInputChange} className='h-8 border border-gray-400 rounded-md px-2 w-full'
              >
                <option>Select payment mode</option>
                <option value="cash">Cash</option>
                <option value="cheque">Cheque</option>
              </select>
            </div>
            <div className='mb-4'>
              <label>Cheque no</label>
              <input
                type="text"
                name="cheque"
                value={formData?.cheque}
                onChange={handleInputChange}
                className='h-8 border border-gray-400 rounded-md px-2 w-full'
                placeholder='Cheque no'
              />
            </div>
            <div className='mb-4'>
              <label>Bank name</label>
              <input
                type="text"
                name="bank_name"
                value={formData?.bank_name}
                onChange={handleInputChange}
                className='h-8 border border-gray-400 rounded-md px-2 w-full'
                placeholder='Bank name'
              />
            </div>
          </div>
          {/* right side */}
          <div className='bg-white h-full px-2 py-2 basis-[50%]' ref={menuRef}>
            <div className='mb-4'>
              <label>Expense type</label>
              <select name="expense_type" onChange={handleInputChange} className='h-8 border border-gray-400 rounded-md px-2 w-full'
              >
                <option>Select expense type</option>
                {expenseType.map(exp => (
                  <option value={exp?.name} key={exp.id}>{exp.name}</option>
                ))}
              </select>
            </div>
            {selectedType === 'accounts' && (
              <div >
                <div className='relative mb-4'>
                  <label>Enter ref no</label>
                  <div className='flex items-center space-x-2 border border-gray-400 py-1 px-2 rounded-md'>
                    <input
                      type="text"
                      value={formData.ref_no}
                      onChange={e => setFormData({ ...formData, ref_no: e.target.value })}
                      className='outline-0 w-full px-2'
                      placeholder='Enter ref no'
                      onFocus={() => setOpen(true)}
                    />
                    <FaSearch />
                  </div>
                  {open && (
                    <div className='bg-white shadow-xl absolute mt-4 w-full px-2 py-2 max-h-[200px] overflow-y-auto'>
                      {
                        importJobData?.map(job => (
                          <p key={job.id} className='bg-gray-100 py-1 px-2 mb-2 text-sm cursor-pointer hover:bg-gray-300' onClick={() => handleSelectedRef(job)}>{job?.ref_no}</p>
                        ))
                      }
                    </div>
                  )}
                </div>
                <div className='mb-4'>
                  <label>Mode</label>
                  <input
                    type="text"
                    name="mode"
                    value={formData?.mode}
                    onChange={handleInputChange}
                    className='h-8 border border-gray-400 rounded-md px-2 w-full'
                    placeholder='Mode'
                  />
                </div>
                <div className='mb-4'>
                  <label>Party</label>
                  <input
                    type="text"
                    name="party"
                    value={formData?.party}
                    onChange={handleInputChange}
                    className='h-8 border border-gray-400 rounded-md px-2 w-full'
                    placeholder='Mode'
                  />
                </div>
                <div className='mb-4'>
                  <label>Total container</label>
                  <input
                    type="text"
                    name="total_container"
                    value={formData?.total_container}
                    onChange={handleInputChange}
                    className='h-8 border border-gray-400 rounded-md px-2 w-full'
                    placeholder='T.container'
                  />
                </div>
                <div className='mb-4'>
                  <label>No of packages</label>
                  <div className='flex items-center space-x-2'>
                    <input
                      type="text"
                      name="num_of_pkgs"
                      value={formData?.num_of_pkgs || ''}
                      className='px-2 w-full  h-8 rounded-md border border-gray-400'
                      onChange={handleInputChange}
                      placeholder='No of pkgs'
                    />
                    <select name="num_of_pkgs_unit" onChange={handleInputChange} className='w-full h-8 border border-gray-400 rounded-md px-2'>
                      <option>{formData?.num_of_pkgs_unit ? formData?.num_of_pkgs_unit : 'Select unit'}</option>
                      {units.map(ut => (
                        <option key={ut?.Indexid} value={ut?.uom}>{ut?.uom}</option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className='mb-4'>
                  <label>Gross wt</label>
                  <div className='flex items-center space-x-2'>
                    <input
                      type="text"
                      name="gross_wt"
                      value={formData?.gross_wt || ''}
                      className='px-2 w-full  h-8 rounded-md border border-gray-400'
                      onChange={handleInputChange}
                      placeholder='Gross weight'

                    />
                    <select name="gross_wt_unit" onChange={handleInputChange} className='w-full h-8 border border-gray-400 rounded-md px-2'>
                      <option>Select gross weight</option>
                      {units.map(ut => (
                        <option key={ut?.Indexid} value={ut?.uom}>{ut?.uom}</option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className='mb-4'>
                  <label>No of packages 1</label>
                  <div className='flex items-center space-x-2'>
                    <input
                      type="text"
                      name="num_of_pkgs1"
                      value={formData?.num_of_pkgs1 || ''}
                      className='px-2 w-full  h-8 rounded-md border border-gray-400'
                      onChange={handleInputChange}
                      placeholder='No of pkgs1'
                    />
                    <select name="num_of_pkgs_unit1" onChange={handleInputChange} className='w-full h-8 border border-gray-400 rounded-md px-2'>
                      <option>Select no of pkgs</option>
                      {units.map(ut => (
                        <option key={ut?.Indexid} value={ut?.uom}>{ut?.uom}</option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className='mb-4'>
                  <label>No of packages 2</label>
                  <div className='flex items-center space-x-2'>
                    <input
                      type="text"
                      name="num_of_pkgs2"
                      value={formData?.num_of_pkgs2 || ''}
                      className='px-2 w-full  h-8 rounded-md border border-gray-400'
                      onChange={handleInputChange}
                      placeholder='No of pkgs1'
                    />
                    <select name="num_of_pkgs_unit2" onChange={handleInputChange} className='w-full h-8 border border-gray-400 rounded-md px-2'>
                      <option>Select no of pkgs2</option>
                      {units.map(ut => (
                        <option key={ut?.Indexid} value={ut?.uom}>{ut?.uom}</option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            )}
            <div className='mb-4'>
              <label>Payment date</label>
              <input
                type="date"
                name="payment_date"
                value={formData?.payment_date}
                onChange={handleInputChange}
                className='h-8 border border-gray-400 rounded-md px-2 w-full'
                placeholder='Payment date'
              />
            </div>
            <div className='mb-4'>
              <label>In favour of</label>
              <input
                type="text"
                name="in_favour_of"
                value={formData?.in_favour_of}
                onChange={handleInputChange}
                className='h-8 border border-gray-400 rounded-md px-2 w-full'
                placeholder='In favour of'
              />
            </div>
          </div>
        </div>
        <div className='mb-4'>
          <label>Remarks</label>
          <input
            type="text"
            name="remarks"
            value={formData?.remarks}
            onChange={handleInputChange}
            className='h-8 border border-gray-400 rounded-md px-2 w-full'
            placeholder='Remarks'
          />
        </div>
        <button className='h-10 bg-blue-700 text-white text-sm font-semibold px-2 w-[200px]'>SUBMIT</button>
      </form>
    </div>
  );
};

export default VoucherEntry;
