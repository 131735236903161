import React from 'react'

const ContainerModal = ({ containerData, handleFormChange, units, saveContainer, errors }) => {
    console.log(errors)
    return (
        <dialog id="my_modal_1" className="w-[800px] absolute top-10 left-[300px] px-4 py-8  rounded-md">
            <div className="">
                <h3 className="font-bold text-lg">Container details</h3>
                <div className='w-full mx-auto flex space-x-2 justify-between mt-9'>
                    <div>
                        <div className='mb-4'>
                            <label>Ref No</label>
                            <input
                                type="text"
                                name="ref_no"
                                value={containerData?.ref_no}
                                onChange={handleFormChange}
                                className='w-full rounded-md h-10 outline-0 text-sm px-2 border border-gray-400'
                            />
                            <br />
                            {errors?.ref_no && <span className='text-sm text-red-400 font-bold'>{errors?.ref_no}</span>}
                        </div>
                        <div className='mb-4'>
                            <label>Container No</label>
                            <input
                                type="text"
                                name="container_no"
                                value={containerData?.container_no}
                                onChange={handleFormChange}
                                className='w-full rounded-md h-10 outline-0 text-sm px-2 border border-gray-400'
                            />
                            <br />
                            {errors?.container_no && <span className='text-sm text-red-400 font-bold'>{errors?.container_no}</span>}
                        </div>
                        <div className='mb-4'>
                            <label>Seal No</label>
                            <input
                                type="text"
                                name="seal_no"
                                value={containerData?.seal_no}
                                onChange={handleFormChange}
                                className='w-full rounded-md h-10 outline-0 text-sm px-2 border border-gray-400'
                            />
                            <br />
                            {errors?.seal_no && <span className='text-sm text-red-400 font-bold'>{errors?.seal_no}</span>}
                        </div>
                        <div className='mb-4'>
                            <label>Size</label>
                            <input
                                type="text"
                                name="size"
                                value={containerData?.size}
                                onChange={handleFormChange}
                                className='w-full rounded-md h-10 outline-0 text-sm px-2 border border-gray-400'
                            />
                            <br />
                            {errors?.size && <span className='text-sm text-red-400 font-bold'>{errors?.size}</span>}
                        </div>
                    </div>
                    <div>
                        <div className='mb-4'>
                            <label>Packages</label>
                            <input
                                type="text"
                                name="pkgs"
                                value={containerData?.pkgs}
                                onChange={handleFormChange}
                                className='w-full rounded-md h-10 outline-0 text-sm px-2 border border-gray-400'
                            />
                            <br />
                            {errors?.pkgs && <span className='text-sm text-red-400 font-bold'>{errors?.pkgs}</span>}
                        </div>
                        <div className='mb-4'>
                            <label>Packages Unit</label>
                            <select name="pkgs_uom" className='w-full rounded-md h-10 outline-0 text-sm px-2 border border-gray-400' onChange={handleFormChange}>
                                <option>{containerData?.pkgs_uom !== '' ? containerData?.pkgs_uom : 'Select pkgs Unit'}</option>
                                {units?.map(u => (
                                    <option value={u.uom}>{u.uom}</option>
                                ))}
                            </select>
                            <br />
                            {errors?.pkgs_uom && <span className='text-sm text-red-400 font-bold'>{errors?.pkgs_uom}</span>}
                        </div>
                        <div className='mb-4'>
                            <label>Gross Weight</label>
                            <input
                                type="text"
                                name="gross_wt"
                                value={containerData?.gross_wt}
                                onChange={handleFormChange}
                                className='w-full rounded-md h-10 outline-0 text-sm px-2 border border-gray-400'
                            />
                            <br />
                            {errors?.gross_wt && <span className='text-sm text-red-400 font-bold'>{errors?.gross_wt}</span>}
                        </div>
                        <div className='mb-4'>
                            <label>Gross weight Unit</label>
                            <select name="gross_wt_uom" className='w-full rounded-md h-10 outline-0 text-sm px-2 border border-gray-400' onChange={handleFormChange}>
                                <option>{containerData?.gross_wt_uom !== '' ? containerData?.gross_wt_uom : 'Select Gross Weight Unit'}</option>
                                {units?.map(u => (
                                    <option value={u.uom}>{u.uom}</option>
                                ))}
                            </select>
                            <br />
                            {errors?.gross_wt_uom && <span className='text-sm text-red-400 font-bold'>{errors?.gross_wt_uom}</span>}
                        </div>
                    </div>
                </div>
                <div className='w-full flex-col text-center items-center'>
                    <button onClick={saveContainer} className='w-[300px] h-[40px]  mt-4 items-center px-4 py-1 bg-blue-600 text-white rounded-sm text-sm'>SAVE</button>
                </div>
                <div className="modal-action">
                    <form method="dialog">
                        {/* if there is a button in form, it will close the modal */}
                        <button className="btn">Close</button>
                    </form>
                </div>
            </div>
        </dialog>
    )
}

export default ContainerModal