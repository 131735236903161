// App.js
import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Profile from "./components/Profile";
import NoAccess from "./components/NoAccess";
import Login from "./components/Login";
import Register from "./components/Register";
import ManageUsers from "./components/ManageUsers";
import ManageRoles from "./components/ManageRoles";
import Dashboard from "./components/Dashboard";
import Masters from "./components/Masters";
import TaxMaster from "./components/TaxMaster";
import TransporterMaster from "./components/TransporterMaster";
import UOMMaster from "./components/UOMMaster";
import ClientMaster from "./components/ClientMaster";
import ClientDetails from "./components/ClientDetails";
import RateMaster from "./components/RateMaster";
import RateDetails from "./components/RateDetails";
import BillTax from "./components/BillTax";
import ChargesMaster from "./components/ChargesMaster";
import InvoiceHead from "./components/InvoiceHead";
import JobDetails from "./components/JobDetails";
import AllVoucherDetails from "./components/AllVoucherDetails";
import FileDelete from "./components/FileDelete";
import FileUpload from "./components/FileUpload";
import IncompleteJobs from "./components/IncompleteJobs";
import ExportJobRegistration from "./components/ExportJobRegistration";
import JobDescription from "./components/JobDescription";
import JobUpdation from "./components/JobUpdation";
import Accounts from "./components/Accounts";
import Billing from "./components/Billing";
import BillPayments from "./components/BillPayments";
import BillingDetails from "./components/BillingDetails";
import CompanyReport from "./components/CompanyReport";
import DeletedInformation from "./components/DeletedInformation";
import GSTPrintAndApprove from "./components/GSTPrintAndApprove";
import PrivateBilling from "./components/PrivateBilling";
import BillView from "./components/BillView";
import JobView from "./components/JobView";
import PrivatePrintApprove from "./components/PrivatePrintApprove";
import UnbilledInformation from "./components/UnbilledInformation";
import VoucherEntry from "./components/VoucherEntry";
import VoucherPayment from "./components/VoucherPayment";
import VoucherPrintApprove from "./components/VoucherPrintApprove";
import Reports from "./components/Reports";
import BillOutstandingReport from "./components/BillOutstandingReport";
import BillPaymentDetailsReport from "./components/BillPaymentDetailsReport";
import BondHistoryDetailsReport from "./components/BondHistoryDetailsReport";
import ClearanceRegisterReport from "./components/ClearanceRegisterReport";
import FileUploadDetailsReport from "./components/FileUploadDetailsReport";
import JobDetailsReport from "./components/JobDetailsReport";
import ServiceTaxReport from "./components/ServiceTaxReport";
import UnclearedJobsReport from "./components/UnclearedJobsReport";
import VoucherDetailsReport from "./components/VoucherDetailsReport";
import Search from "./components/Search";
import ViewLog from "./components/ViewLog";
import Header from "./components/Header";
import Navbar from "./components/Navbar";
import ImportJobRegistration from "./components/ImportJobRegistration";
import ImportJobUpdation from "./components/ImportJobUpdation";
import "./App.css";

import { AuthProvider } from "./AuthContext";
import ProtectedRoute from "./ProtectedRoute";


function App() {
  const [username] = useState("");

  return (
    <AuthProvider>
      <Router>
        <div className="h-screen">
          <Header username={username} />
          <Navbar />
          <div className="bg-white px-4 h-full">
            <Routes>
              <Route path="/" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route path="/no-access" element={<NoAccess />} />
              <Route
                path="/dashboard"
                element={<ProtectedRoute name="Dashboard" component={Dashboard} />}
              />
              <Route path="/user-management/manage-users"
                element={<ProtectedRoute name="ManageUsers" component={ManageUsers} />} />

              <Route path="/user-management/manage-roles"
                element={<ProtectedRoute name="ManageRoles" component={ManageRoles} />} />
              <Route path="/profile"
                element={<ProtectedRoute name="Profile" component={Profile} />} />
              <Route
                path="/masters"
                element={<ProtectedRoute name="Masters" component={Masters} />}
              />
              <Route
                path="/masters/tax-master"
                element={<ProtectedRoute name="TaxMaster" component={TaxMaster} />}
              />
              <Route
                path="/masters/transporter-master"
                element={<ProtectedRoute name="TransporterMaster" component={TransporterMaster} />}
              />
              <Route
                path="/masters/uom-master"
                element={<ProtectedRoute name="UOMMaster" component={UOMMaster} />}
              />
              <Route
                path="/masters/client-master"
                element={<ProtectedRoute name="ClientMaster" component={ClientMaster} />}
              />
              <Route
                path="/masters/client-details"
                element={<ProtectedRoute name="ClientDetails" component={ClientDetails} />}
              />
              <Route
                path="/masters/charges-master"
                element={<ProtectedRoute name="ChargesMaster" component={ChargesMaster} />}
              />
              <Route
                path="/masters/invoice-head"
                element={<ProtectedRoute name="InvoiceHead" component={InvoiceHead} />}
              />
              <Route
                path="/masters/rate-master"
                element={<ProtectedRoute name="RateMaster" component={RateMaster} />}
              />
              <Route
                path="/masters/rate-details"
                element={<ProtectedRoute name="RateDetails" component={RateDetails} />}
              />
              <Route path="/masters/bill-tax"
                element={<ProtectedRoute name="BillTax" component={BillTax} />}
              />
              <Route
                path="/job-details"
                element={<ProtectedRoute name="JobDetails" component={JobDetails} />}
              />
              <Route
                path="/job-details/export-job-registration"
                element={<ProtectedRoute name="ExportJobRegistration" component={ExportJobRegistration} />}
              />
              <Route
                path="/job-details/import-job-registration"
                element={<ProtectedRoute name="ImportJobRegistration" component={ImportJobRegistration} />}
              />
              <Route
                path="/job-details/import-job-updation"
                element={<ProtectedRoute name="ImportJobUpdation" component={ImportJobUpdation} />}
              />
              <Route
                path="/job-details/all-voucher-details"
                element={<ProtectedRoute name="AllVoucherDetails" component={AllVoucherDetails} />}
              />
              <Route
                path="/job-details/file-delete"
                element={<ProtectedRoute name="FileDelete" component={FileDelete} />}
              />
              <Route
                path="/job-details/file-upload"
                element={<ProtectedRoute name="FileUpload" component={FileUpload} />}
              />
              <Route
                path="/job-details/incomplete-jobs"
                element={<ProtectedRoute name="IncompleteJobs" component={IncompleteJobs} />}
              />
              <Route
                path="/job-details/job-description"
                element={<ProtectedRoute name="JobDescription" component={JobDescription} />}
              />
              <Route
                path="/job-details/job-updation"
                element={<ProtectedRoute name="JobUpdation" component={JobUpdation} />}
              />
              <Route
                path="/accounts"
                element={<ProtectedRoute name="Accounts" component={Accounts} />}
              />
              <Route
                path="/accounts/private-billing"
                element={<ProtectedRoute name="Billing" component={Billing} />}
              />
              <Route
                path="/accounts/bill-payments"
                element={<ProtectedRoute name="BillPayments" component={BillPayments} />}
              />
              <Route
                path="/accounts/billing-details"
                element={<ProtectedRoute name="BillingDetails" component={BillingDetails} />}
              />
              <Route
                path="/accounts/company-report"
                element={<ProtectedRoute name="CompanyReport" component={CompanyReport} />}
              />
              <Route
                path="/accounts/deleted-information"
                element={<ProtectedRoute name="DeletedInformation" component={DeletedInformation} />}
              />
              <Route
                path="/accounts/gst-print-approve"
                element={<ProtectedRoute name="GSTPrintAndApprove" component={GSTPrintAndApprove} />}
              />
              <Route
                path="/accounts/billing"
                element={<ProtectedRoute name="PrivateBilling" component={PrivateBilling} />}
              />
              <Route
                path="/accounts/private-print-approve"
                element={<ProtectedRoute name="PrivatePrintApprove" component={PrivatePrintApprove} />}
              />
              <Route
                path="/accounts/unbilled-information"
                element={<ProtectedRoute name="UnbilledInformation" component={UnbilledInformation} />}
              />
              <Route
                path="/accounts/voucher-entry"
                element={<ProtectedRoute name="VoucherEntry" component={VoucherEntry} />}
              />
              <Route
                path="/accounts/voucher-payment"
                element={<ProtectedRoute name="VoucherPayment" component={VoucherPayment} />}
              />
              <Route
                path="/accounts/voucher-print-approve"
                element={<ProtectedRoute name="VoucherPrintApprove" component={VoucherPrintApprove} />}
              />
              <Route
                path="/reports"
                element={<ProtectedRoute name="Reports" component={Reports} />}
              />
              <Route
                path="/reports/bill-outstanding-report"
                element={<ProtectedRoute name="BillOutstandingReport" component={BillOutstandingReport} />}
              />
              <Route
                path="/reports/bill-payment-details-report"
                element={<ProtectedRoute name="BillPaymentDetailsReport" component={BillPaymentDetailsReport} />}
              />
              <Route
                path="/reports/bond-history-details-report"
                element={<ProtectedRoute name="BondHistoryDetailsReport" component={BondHistoryDetailsReport} />}
              />
              <Route
                path="/reports/clearance-register-report"
                element={<ProtectedRoute name="ClearanceRegisterReport" component={ClearanceRegisterReport} />}
              />
              <Route
                path="/reports/file-upload-details-report"
                element={<ProtectedRoute name="FileUploadDetailsReport" component={FileUploadDetailsReport} />}
              />
              <Route
                path="/reports/job-details-report"
                element={<ProtectedRoute name="JobDetailsReport" component={JobDetailsReport} />}
              />
              <Route
                path="/reports/service-tax-report"
                element={<ProtectedRoute name="ServiceTaxReport" component={ServiceTaxReport} />}
              />
              <Route
                path="/reports/uncleared-jobs-report"
                element={<ProtectedRoute name="UnclearedJobsReport" component={UnclearedJobsReport} />}
              />
              <Route
                path="/reports/voucher-details-report"
                element={<ProtectedRoute name="VoucherDetailsReport" component={VoucherDetailsReport} />}
              />
              <Route
                path="/search"
                element={<ProtectedRoute name="Search" component={Search} />}
              />
              <Route
                path="/view-log"
                element={<ProtectedRoute name="ViewLog" component={ViewLog} />}
              />
              <Route path="/billing/view/:bill_no" element={<ProtectedRoute name="BillView" component={BillView} />} />
              <Route path="/job/view/:ref_no" element={<ProtectedRoute name="JobView" component={JobView} />} />
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </div>
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;
