import moment from 'moment'
import React from 'react'

const ShipmentSea = ({ formData, units, handleChange, errors }) => {
    return (
        <div>
            <div className='flex items-center space-x-4'>
                <div>
                    <label>Country of shipment</label>
                    <input
                        type="text"
                        name="country_of_shipment"
                        value={formData?.country_of_shipment}
                        onChange={handleChange}
                        className='w-full h-8 border border-gray-400 rounded-md px-2'
                    />
                </div>
                <div>
                    <label>Port of shippment</label>
                    <input
                        type="text"
                        name="port_of_shipment"
                        value={formData?.port_of_shipment}
                        onChange={handleChange}
                        className='w-full h-8 border border-gray-400 px-2 rounded-md'
                    />
                </div>
            </div>
            <div className='flex items-center space-x-4 my-2'>
                <div>
                    <label>Mother vessel name</label>
                    <input
                        type="text"
                        name="mother_vessel_name"
                        value={formData?.mother_vessel_name}
                        onChange={handleChange}
                        className='w-full h-8 border border-gray-400 px-2 rounded-md'
                    />
                </div>
                <div>
                    <label>V no</label>
                    <input
                        type="text"
                        name="v_no"
                        value={formData?.v_no}
                        onChange={handleChange}
                        className='w-full h-8 border border-gray-400 px-2 rounded-md'
                    />
                </div>
                <div>
                    <label>ETA</label>
                    <input
                        type="date"
                        name="eta"
                        value={moment(formData?.eta).format("YYYY-MM-DD") || null}
                        onChange={handleChange}
                        className='w-full h-8 border border-gray-400 px-2 rounded-md'
                    />
                </div>
                <div>
                    <label>Remainder</label>
                    <input
                        type="checkbox"
                        name="remainder"
                        value={formData?.remiander}
                        onChange={handleChange}
                        className='w-full h-4 border border-gray-400 px-2 rounded-md'
                    />
                </div>
            </div>
            <div className='flex items-center space-x-4 my-2'>
                <div>
                    <label>Feeder vessel name</label>
                    <input
                        type="text"
                        name="feeder_vessel_name"
                        value={formData?.feeder_vessel_name}
                        onChange={handleChange}
                        className='w-full h-8 border border-gray-400 px-2 rounded-md'
                    />
                </div>
                <div>
                    <label>V.no</label>
                    <input
                        type="text"
                        name="feeder_v_no"
                        value={formData?.feeder_v_no}
                        onChange={handleChange}
                        className='w-full h-8 border border-gray-400 px-2 rounded-md'
                    />
                </div>
                <div>
                    <label>ETA</label>
                    <input
                        type="date"
                        name="feeder_eta"
                        value={moment(formData?.feeder_eta).format("YYYY-MM-DD") || null}
                        onChange={handleChange}
                        className='w-full h-8 border border-gray-400 px-2 rounded-md'
                    />
                </div>
                <div>
                    <label>Forwader name</label>
                    <input
                        type="text"
                        name="forwarder"
                        value={formData?.forwarder}
                        onChange={handleChange}
                        className='w-full h-8 border border-gray-400 px-2 rounded-md'
                    />
                </div>
            </div>
            <div className='flex items-center space-x-4 my-2'>
                <div>
                    <label>Shipping line</label>
                    <input
                        type="text"
                        name="shipping_line"
                        value={formData?.shipping_line}
                        onChange={handleChange}
                        className='w-full h-8 border border-gray-400 px-2 rounded-md'
                    />
                </div>
                <div>
                    <label>Rotation</label>
                    <input
                        type="text"
                        name="rotation"
                        value={formData?.rotation}
                        onChange={handleChange}
                        className='w-full h-8 border border-gray-400 px-2 rounded-md'
                    />
                </div>
                <div>
                    <label>Date</label>
                    <input
                        type="date"
                        name="rotation_date"
                        value={moment(formData?.rotation_date).format("YYYY-MM-DD") || ''}
                        onChange={handleChange}
                        className='w-full h-8 border border-gray-400 px-2 rounded-md'
                    />
                </div>
                <div>
                    <label>Line no</label>
                    <input
                        type="text"
                        name="line_no"
                        value={formData?.line_no}
                        onChange={handleChange}
                        className='w-full h-8 border border-gray-400 px-2 rounded-md'
                    />
                </div>
            </div>
            <div className='flex items-center space-x-4 my-2'>
                <div>
                    <label>MBL No</label>
                    <input
                        type="Number"
                        name="mbl_no"
                        value={formData?.mbl_no}
                        onChange={handleChange}
                        className='w-full h-8 border border-gray-400 px-2 rounded-md'
                    />
                </div>
                <div>
                    <label>Date</label>
                    <input
                        type="date"
                        name="mbl_date"
                        value={moment(formData?.mbl_date).format("YYYY-MM-DD") || ''}
                        onChange={handleChange}
                        className='w-full h-8 border border-gray-400 px-2 rounded-md'
                    />
                </div>
                <div>
                    <label>HBL No</label>
                    <input
                        type="text"
                        name="hbl_no"
                        value={formData?.hbl_no}
                        onChange={handleChange}
                        className='w-full h-8 border border-gray-400 px-2 rounded-md'
                    />
                </div>
                <div>
                    <label>Date</label>
                    <input
                        type="date"
                        name="hbl_date"
                        value={moment(formData?.hbl_date).format("YYYY-MM-DD") || ''}
                        onChange={handleChange}
                        className='w-full h-8 border border-gray-400 px-2 rounded-md'
                    />
                </div>
            </div>
            <div className='flex items-center space-x-4 my-2'>
                <div>
                    <label>CBM</label>
                    <input
                        type="text"
                        name="cbm"
                        value={formData?.cbm}
                        onChange={handleChange}
                        className='w-full h-8 border border-gray-400 px-2 rounded-md'
                    />
                </div>
                <div>
                    <label>CFS</label>
                    <input
                        type="text"
                        name="cfs"
                        value={formData?.cfs}
                        onChange={handleChange}
                        className='w-full h-8 border border-gray-400 px-2 rounded-md'
                    />
                </div>
            </div>
            <div className='flex items-center space-x-4 my-2'>
                <div>
                    <label>Net wt</label>
                    <div className='flex items-center space-x-2'>
                        <input
                            type="text"
                            name="net_wt"
                            value={formData?.net_wt || ''}
                            className='px-2 w-[100px]  h-8 rounded-md border border-gray-400'
                            onChange={handleChange}
                        />
                        <select name="net_wt_unit" onChange={handleChange} className='w-full h-8 border border-gray-400 rounded-md px-2'>
                            <option>{formData?.net_wt_unit !== null ? formData?.net_wt_unit : 'Select net weight'}</option>
                            {units.map(ut => (
                                <option key={ut?.Indexid} value={ut?.uom}>{ut?.uom}</option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className='h-[20px] mt-6 w-[1px] bg-gray-600'></div>
                <div>
                    <label>Gross wt</label>
                    <div className='flex items-center space-x-2'>
                        <input
                            type="text"
                            name="gross_wt"
                            value={formData?.gross_wt || ''}
                            className='px-2 w-[100px]  h-8 rounded-md border border-gray-400'
                            onChange={handleChange}

                        />
                        <select name="gross_wt_unit" onChange={handleChange} className='w-full h-8 border border-gray-400 rounded-md px-2'>
                            <option>{formData?.gross_wt_unit !== null ? formData?.gross_wt_unit : 'Select gross weight'}</option>
                            {units.map(ut => (
                                <option key={ut?.Indexid} value={ut?.uom}>{ut?.uom}</option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className='h-[20px] mt-6 w-[1px] bg-gray-600'></div>
                <div>
                    <label>Charges wt</label>
                    <div className='flex items-center space-x-2'>
                        <input
                            type="text"
                            name="charges_wt"
                            value={formData?.charges_wt || ''}
                            className='px-2 w-[100px]  h-8 rounded-md border border-gray-400'
                            onChange={handleChange}
                        />
                        <select name="charges_wt_unit" onChange={handleChange} className='w-full h-8 border border-gray-400 rounded-md px-2'>
                            <option>{formData?.charges_wt_unit !== null ? formData?.charges_wt_unit : 'Select charges weight'}</option>
                            {units.map(ut => (
                                <option key={ut?.Indexid} value={ut?.uom}>{ut?.uom}</option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className='h-[20px] mt-6 w-[1px] bg-gray-600'></div>
                <div>
                    <label>No of packages</label>
                    <div className='flex items-center space-x-2'>
                        <input
                            type="text"
                            name="num_of_pkgs"
                            value={formData?.num_of_pkgs || ''}
                            className='px-2 w-[100px]  h-8 rounded-md border border-gray-400'
                            onChange={handleChange}
                        />
                        <select name="num_of_pkgs_unit" onChange={handleChange} className='w-full h-8 border border-gray-400 rounded-md px-2'>
                            <option>{formData?.num_of_pkgs_unit !== null ? formData?.num_of_pkgs_unit : 'Select charges weight'}</option>
                            {units.map(ut => (
                                <option key={ut?.Indexid} value={ut?.uom}>{ut?.uom}</option>
                            ))}
                        </select>
                    </div>
                </div>
                {formData?.fcl_lcl === 'LCL' && (
                    <div className='w-[300px]'>
                        <label>Container no</label>
                        <input
                            type="text"
                            name="container_no"
                            value={formData?.container_no || ''}
                            className='px-2 w-full  h-8 rounded-md border border-gray-400'
                            onChange={handleChange}
                            placeholder='container no'

                        />
                        {errors?.container_no && <span className='text-red-400 text-sm text-center w-full pt-4'>{errors?.container_no}</span>}
                    </div>
                )}

            </div>
            <div className='flex items-center space-x-4 my-2'>
                <div>
                    <label>STC</label>
                    <div className='flex items-center space-x-2'>
                        <input
                            type="text"
                            name="stc"
                            value={formData?.stc || ''}
                            className='px-2 w-[100px]  h-8 rounded-md border border-gray-400'
                            onChange={handleChange}
                        />
                        <select name="stc_unit" onChange={handleChange} className='w-full h-8 border border-gray-400 rounded-md px-2'>
                            <option>{formData?.stc_unit !== null && formData?.stc_unit !== '' ? formData?.stc_unit : 'Select charges weight'}</option>
                            {units.map(ut => (
                                <option key={ut?.Indexid} value={ut?.uom}>{ut?.uom}</option>
                            ))}
                        </select>
                    </div>
                </div>
                <div>
                    <label>STC 2</label>
                    <div className='flex items-center space-x-2'>
                        <input
                            type="text"
                            name="stc2"
                            value={formData?.stc2 || ''}
                            className='px-2 w-[100px]  h-8 rounded-md border border-gray-400'
                            onChange={handleChange}
                        />
                        <select name="stc2_unit" onChange={handleChange} className='w-full h-8 border border-gray-400 rounded-md px-2'>
                            <option>{formData?.stc2_unit !== null && formData?.stc2_unit !== '' ? formData?.stc2_unit : 'Select charges weight'}</option>
                            {units.map(ut => (
                                <option key={ut?.Indexid} value={ut?.uom}>{ut?.uom}</option>
                            ))}
                        </select>
                    </div>
                </div>
                <div>
                    <label>SIZE 20</label>
                    <input
                        type="text"
                        name="size_20"
                        value={formData?.size_20}
                        onChange={handleChange}
                        className='w-full h-8 border border-gray-400 px-2 rounded-md'
                    />
                </div>
                <div>
                    <label>SIZE 40</label>
                    <input
                        type="text"
                        name="size_40"
                        value={formData?.size_40}
                        onChange={handleChange}
                        className='w-full h-8 border border-gray-400 px-2 rounded-md'
                    />
                </div>
                {formData.fcl_lcl === 'FCL' && (
                    <>
                        <div >
                            <label className='w-[200px]'>Total container</label>
                            <input
                                type="number"
                                name="total_container"
                                value={formData?.total_container}
                                onChange={handleChange}
                                className='w-full h-8 border border-gray-400 px-2 rounded-md'
                            />
                            <br />
                            {errors?.total_container && <span className='w-full mt-6 text-red-400 text-sm text-center'>{errors?.total_container}</span>}
                        </div>
                    </>
                )}

            </div>
        </div>
    )
}

export default ShipmentSea