import React, { useEffect, useRef, useState } from 'react'
import { FaSearch } from 'react-icons/fa'

const Search = ({ refNo, handleChange, records }) => {
    const [selectedRecord, setSelectedRecord] = useState(null);
    const [selectedItem, setSelectedItem] = useState(-1);
    const [open, setOpen] = useState(false);
    const menuRef = useRef();



    const handleOpen = () => setOpen(!open);

    const handleKeyDown = (e) => {
        if (e.key === 'ArrowUp' && selectedItem > 0) {
            setSelectedItem(prev => prev - 1);
        } else if (e.key === 'ArrowDown' && selectedItem < records.length - 1) {
            setSelectedItem(prev => prev + 1);
        } else if (e.key === 'Enter') {
            setSelectedRecord(records[selectedItem]?.ref_no)
            setOpen(false)
        }
    }

    useEffect(() => {
        const handler = (e) => {
            if (!menuRef.current.contains(e.target)) {
                setOpen(false);
            }
        }
        document.addEventListener('mousedown', handler)
        return () => {
            document.removeEventListener("mousedown", handler);
        }
    }, [])
    return (
        <div>
            <div className="relative w-[400px] mx-auto" ref={menuRef}>
                <div className="w-[350px] mx-auto flex items-center justify-between border border-gray-400 rounded-md px-2 py-2 mb-4">
                    <input
                        type="text"
                        placeholder="Search here..."
                        value={refNo || ''}
                        onChange={handleChange}
                        onClick={handleOpen}
                        className="outline-0 border-0 w-full"
                    />
                    <span className="mr-2 text-sm font-normal text-blue-900">{selectedRecord}</span>
                    <FaSearch size={16} />
                </div>
            </div>
        </div>
    )
}

export default Search