import moment from 'moment'
import React from 'react'

const ShipmentBoe = ({ formData, units, handleChange }) => {
    return (
        <div>
            <div className='flex items-center space-x-4'>
                <div>
                    <label>Place of shipment</label>
                    <input
                        type="text"
                        name="place_of_shipment"
                        value={formData?.place_of_shipment}
                        onChange={handleChange}
                        className='w-full h-8 border border-gray-400 rounded-md px-2'
                    />
                </div>
                <div>
                    <label>Place of Delivery</label>
                    <input
                        type="text"
                        name="place_of_delivery"
                        value={formData?.place_of_delivery}
                        onChange={handleChange}
                        className='w-full h-8 border border-gray-400 rounded-md px-2'
                    />
                </div>
            </div>
            <div className='flex items-center space-x-4 my-2'>
                <div>
                    <label>Net wt</label>
                    <div className='flex items-center space-x-2'>
                        <input
                            type="text"
                            name="net_wt"
                            value={formData?.net_wt || ''}
                            className='px-2 w-[100px]  h-8 rounded-md border border-gray-400'
                            onChange={handleChange}
                        />
                        <select name="net_wt_unit" onChange={handleChange} className='w-full h-8 border border-gray-400 rounded-md px-2'>
                            <option>{formData?.net_wt_unit !== null && formData?.net_wt_unit !== '' ? formData?.net_wt_unit : 'Select charges weight'}</option>
                            {units.map(ut => (
                                <option key={ut?.Indexid} value={ut?.uom}>{ut?.uom}</option>
                            ))}
                        </select>
                    </div>

                </div>
                <div>
                    <label>Gross wt</label>
                    <div className='flex items-center space-x-2'>
                        <input
                            type="text"
                            name="gross_wt"
                            value={formData?.gross_wt || ''}
                            className='px-2 w-[100px]  h-8 rounded-md border border-gray-400'
                            onChange={handleChange}

                        />
                        <select name="gross_wt_unit" onChange={handleChange} className='w-full h-8 border border-gray-400 rounded-md px-2'>
                            <option>{formData?.gross_wt_unit !== null && formData?.gross_wt_unit !== '' ? formData?.gross_wt_unit : 'Select gross weight'}</option>
                            {units.map(ut => (
                                <option key={ut?.Indexid} value={ut?.uom}>{ut?.uom}</option>
                            ))}
                        </select>
                    </div>

                </div>
                <div>
                    <label>No of packages</label>
                    <div className='flex items-center space-x-2'>
                        <input
                            type="text"
                            name="num_of_pkgs"
                            value={formData?.num_of_pkgs || ''}
                            className='px-2 w-[100px]  h-8 rounded-md border border-gray-400'
                            onChange={handleChange}
                        />
                        <select name="num_of_pkgs_unit" onChange={handleChange} className='w-full h-8 border border-gray-400 rounded-md px-2'>
                            <option>{formData?.num_of_pkgs_unit !== null ? formData?.num_of_pkgs_unit : 'Select no of pkgs'}</option>
                            {units.map(ut => (
                                <option key={ut?.Indexid} value={ut?.uom}>{ut?.uom}</option>
                            ))}
                        </select>
                    </div>
                </div>
            </div>
            <div className='flex items-center space-x-4'>
                <div>
                    <label>Goods Rcvd no</label>
                    <input
                        type="date"
                        name="goods_rcvd_on"
                        value={moment(formData?.goods_rcvd_on).format("YYYY-MM-DD") || ''}
                        onChange={handleChange}
                        className='w-full h-8 border border-gray-400 rounded-md px-2'
                    />
                </div>
                <div>
                    <label>ARE1 No</label>
                    <input
                        type="text"
                        name="are1_no"
                        value={formData?.are1_no}
                        onChange={handleChange}
                        className='w-full h-8 border border-gray-400 rounded-md px-2'
                    />
                </div>
                <div>
                    <label>ARE1 date</label>
                    <input
                        type="date"
                        name="are1_date"
                        value={moment(formData?.are1_date).format("YYYY-MM-DD") || ''}
                        onChange={handleChange}
                        className='w-full h-8 border border-gray-400 rounded-md px-2'
                    />
                </div>
            </div>
        </div>
    )
}

export default ShipmentBoe