import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import './styles/FileUpload.css';
import { FaSearch } from "react-icons/fa";
import Search from './search/Search';


const BASE_URL = process.env.REACT_APP_BACKEND_URL + 'file-upload';

const FileUpload = () => {
  const [refNo, setRefNo] = useState('');
  const [open, setOpen] = useState(false);
  const [records, setRecords] = useState([]);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [uploadFiles, setUploadFiles] = useState([]);
  const [files, setFiles] = useState([]);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const menuRef = useRef();

  const handleSearch = async () => {
    const BASE_URL = process.env.REACT_APP_BACKEND_URL
    try {
      if (refNo !== '') {
        const response = await axios.get(BASE_URL + `import-job/search?q=${refNo}`);
        setRecords(response.data);
        setOpen(true);
      }
    } catch (error) {
      alert(error?.response.data?.message)
      console.error('Error fetching records:', error);
    }
  };
  const handleRecordSelect = (record) => {
    setRefNo(record?.ref_no);
    setSelectedRecord(record?.ref_no);
    setOpen(false);
  };

  useEffect(() => {
    handleSearch()
    fetchFiles();
    const handler = (e) => {
      if (!menuRef.current.contains(e.target)) {
        setOpen(false);
      }
    }
    document.addEventListener('mousedown', handler)
    return () => {
      document.removeEventListener("mousedown", handler);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refNo]);



  const fetchFiles = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/list/files`);
      setFiles(response.data);
    } catch (err) {
      console.error('Error fetching files:', err);
      setError('Error fetching files');
    }
  };

  const handleFileChange = async (e) => {
    const fd = new FormData();
    for (const key of Object.keys(e.target.files)) {
      fd.append('file', e.target.files[key])
    }
    try {
      const result = await axios.post(BASE_URL, fd, { headers: { 'Content-Type': 'multipart/form-data' } });
      setUploadFiles(result.data);
    } catch (error) {
      setError('Error uploading file.')
    }

  };

  const handleUpload = async () => {
    if (!uploadFiles || !refNo) {
      setError('Reference number and file are required');
      setTimeout(() => {
        setError('');
      }, 5000);
      return;
    }
    try {
      const fileData = {
        ref_no: refNo,
        job_files: uploadFiles
      }
      console.log(fileData);
      await axios.post(`${BASE_URL}/data/upload`, fileData, { headers: { 'Content-Type': 'application/json' } })
      setSuccessMessage('File uploaded successfully');
      setTimeout(() => {
        setSuccessMessage('');
      }, 5000);
      fetchFiles();
    } catch (err) {
      console.error('Error uploading file:', err);
      setError('Error uploading file');
      setTimeout(() => {
        setError('');
      }, 5000);
    }
  };


  const handleFileDownload = async (fileName) => {
    try {
      const response = await axios.get(BASE_URL + `/download/${fileName}`, { responseType: 'blob' });
      const file = new Blob([response.data], { type: response.headers['content-type'] });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(file);
      link.download = fileName; // File name when saved on the user’s computer
      link.click();
    } catch (error) {
      console.log(error?.response)
    }
  }


  return (
    <div className="file-upload-container pt-4">
      <h1 className="file-upload-title">File Upload</h1>

      <div className="bg-white w-full px-4 mx-auto py-4" >
        <div className='relative' ref={menuRef}>
          <div className='w-[350px] mx-auto flex items-center space-x-4 border border-gray-400 px-2 rounded-[5px]'>
            <input
              type="text"
              value={refNo || ''}
              onChange={(e) => setRefNo(e.target.value)}
              className='w-full  px-2 py-2 rounded-sm outline-0'
              placeholder='Search reference no'
            />
            <span className='text-blue-600 font-bold text-sm'>{selectedRecord?.ref_no}</span>
            <FaSearch size='16' />
          </div>
          {(records.length > 0 && open) && (
            <ul className='w-[350px] max-h-[300px] overflow-y-auto shadow-lg bg-white px-2 mt-1 absolute left-[390px] z-30'>
              {records.map(record => (
                <li key={record?.id} className='bg-gray-50 mb-2 px-2 py-1 hover:bg-gray-200 cursor-pointer' onClick={() => handleRecordSelect(record)}>
                  <span className='text-sm text-black font-normal'>{record?.ref_no}</span>
                </li>
              ))}
            </ul>
          )}
        </div>

        <div className='w-[400px] mx-auto mb-4 pb-4 pt-4'>
          <label className="file-upload-label">Choose Files</label>
          <input
            type="file"
            className="file-upload-file-input"
            onChange={handleFileChange}
            multiple
          />

          <button
            className="file-upload-button"
            onClick={handleUpload}
          >
            Upload
          </button>
        </div>


        {error && <p className="file-upload-error">{error}</p>}
        {successMessage && <p className="file-upload-success">{successMessage}</p>}
      </div>

      {files.length > 0 && (
        <div className="file-upload-list">
          <h2 className="file-upload-list-title">Uploaded Files</h2>
          <table className="file-upload-table">
            <thead>
              <tr>
                <th>Ref No</th>
                <th>File Name</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {files.map((file) => (
                <tr key={file.id}>
                  <td>{file?.ref_no}</td>
                  <td>
                    {file?.job_files && JSON.parse(file?.job_files).map((f, i) => (
                      <li key={i} className='list-none mb-2'>{f?.fileName}</li>
                    ))}
                  </td>
                  <td>
                    {file?.job_files && JSON.parse(file?.job_files).map((f, i) => (
                      <div className='mb-4'>
                        <button onClick={() => handleFileDownload(f?.fileName)} className='px-2 py-2 bg-green-800 text-xs text-white font-bold'>DOWNLOAD</button>
                      </div>
                    ))}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )
      }
    </div >
  );
};

export default FileUpload;
