import React, { useState, useEffect } from 'react';
import './styles/IncompleteJobs.css';
import 'jspdf-autotable';
import jsPDF from 'jspdf';
import * as XLSX from 'xlsx';
import axios from 'axios';

const UnclearedJobsReport = () => {
  const [dateCondition, setDateCondition] = useState('equal');
  const [date, setDate] = useState('');
  const [refNo, setRefNo] = useState('');
  const [party, setParty] = useState('');
  const [allJobs, setAllJobs] = useState([]);
  const [filteredJobs, setFilteredJobs] = useState([]);

  useEffect(() => {
    // Fetch all data once on component mount
    const fetchAllJobs = async () => {
      try {
        const response = await axios.get(process.env.REACT_APP_BACKEND_URL + 'incomplete-jobs/report/getUnclearedJobs');
        setAllJobs(response.data);
        setFilteredJobs(response.data); // Initially display all jobs
      } catch (error) {
        console.error('Error fetching all jobs:', error);
      }
    };

    fetchAllJobs();
  }, []);

  const handleFilter = (e) => {
    e.preventDefault();
    let filtered = [...allJobs]; // Create a copy of all jobs

    if (date) {
      const filterDate = new Date(date);

      filtered = filtered.filter(job => {
        const jobDate = new Date(job.date);

        switch (dateCondition) {
          case 'equal':
            return jobDate.toDateString() === filterDate.toDateString();
          case 'before':
            return jobDate < filterDate;
          case 'after':
            return jobDate > filterDate;
          default:
            return false;
        }
      });
    }

    if (refNo) {
      filtered = filtered.filter(job => job.ref_no === refNo);
    }

    if (party) {
      filtered = filtered.filter(job => job.importer_name === party);
    }

    setFilteredJobs(filtered);
  };

  const downloadPDF = () => {
    const doc = new jsPDF();
    doc.autoTable({
      head: [['Service Type', 'Transport Mode', 'Bill Type', 'Reference No', 'Date', 'Party']],
      body: filteredJobs.map(job => [
        job.service_type, job.trans_mode, job.be_type, job.ref_no, formatTimestamp(job.date), job.importer_name
      ]),
    });
    doc.save('uncleared_jobs.pdf');
  };

  const downloadExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(filteredJobs);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Jobs');
    XLSX.writeFile(workbook, 'uncleared_jobs.xlsx');
  };


  const formatTimestamp = (timestamp) => {
    if (!timestamp) return '';
    const date = new Date(timestamp);
    return date.toLocaleString();
  };

  return (
    <div className="w-full px-4">
      <h1 className="incomplete-jobs-title">Uncleared Jobs Report</h1>
      <div className="incomplete-jobs-form">

        <form className="w-full flex items-center space-x-4">
          <label className="incomplete-jobs-label" htmlFor="dateCondition">Date Condition:</label>
          <select
            id="dateCondition"
            className="incomplete-jobs-dropdown"
            value={dateCondition}
            onChange={(e) => setDateCondition(e.target.value)}
          >
            <option value="equal">Equal</option>
            <option value="before">Before</option>
            <option value="after">After</option>
          </select>
          <label className="incomplete-jobs-label" htmlFor="date">Date:</label>
          <input
            type="date"
            id="date"
            className="incomplete-jobs-input"
            value={date}
            onChange={(e) => setDate(e.target.value)}
          />
          <label htmlFor="refNo">Reference No:</label>
          <input
            type="text"
            id="refNo"
            className='incomplete-jobs-input'
            value={refNo}
            onChange={(e) => setRefNo(e.target.value)}
          />

          <label htmlFor="party">Party:</label>
          <input
            type="text"
            id="party"
            className='incomplete-jobs-input'
            value={party}
            onChange={(e) => setParty(e.target.value)}
          />
          <div className="incomplete-jobs-button-group">
            <button
              className="incomplete-jobs-button incomplete-jobs-button-show"
              onClick={handleFilter}
            >
              Filter
            </button>
          </div>
        </form>
        <button className="px-4 bg-blue-800 text-white" onClick={downloadPDF}>Download PDF</button>
        <button className="file-upload-download-button" onClick={downloadExcel}>Download Excel</button>
      </div>

      {filteredJobs.length > 0 ? (
        <table className="incomplete-jobs-table">
          <thead>
            <tr>
              <th>Service Type</th>
              <th>Transport Mode</th>
              <th>Bill Type</th>
              <th>Reference No</th>
              <th>Date</th>
              <th>Party</th>
            </tr>
          </thead>
          <tbody>
            {filteredJobs.map((job) => (
              <tr key={job.ref_no}>
                <td>{job.service_type}</td>
                <td>{job.trans_mode}</td>
                <td>{job.be_type}</td>
                <td>{job.ref_no}</td>
                <td>{new Date(job.date).toLocaleDateString()}</td>
                <td>{job.importer_name}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p className="incomplete-jobs-message">No incomplete jobs found based on the selected criteria.</p>
      )}
    </div>
  );
};

export default UnclearedJobsReport;
