import React, { useState } from 'react';
import './styles/ImportJobRegistration.css';
import axios from 'axios';
import useFetch from '../utils/useFetch';
import { transportMode } from '../constants/constants';
import ShipmentDetails from './shipment-details/ShipmentDetails';
import ShipmentBoe from './shipment-details/ShipmentBoe';
import ExBond from './shipment-details/ExBond';
import ShipmentSea from './shipment-details/ShipmentSea';
import ShipmentRoad from './shipment-details/ShipmentRoad';
import Transfer from './shipment-details/Transfer';
import Courier from './shipment-details/Courier';
import InvoiceModal from './modal/InvoiceModal';
import FreightModal from './modal/FreightModal';
import moment from 'moment';
import ContainerModal from './modal/ContainerModal';


const BASE_URL = process.env.REACT_APP_BACKEND_URL + 'import-job';

const ImportJobRegistration = () => {
    const { units, currencies } = useFetch();
    const [containerData, setContainerData] = useState({
        ref_no: '',
        container_no: '',
        seal_no: '',
        size: '',
        pkgs: '',
        pkgs_uom: '',
        gross_wt: '',
        gross_wt_uom: ''
    })
    const [formData, setFormData] = useState({
        date: null,
        shipmentDate: null,
        service_type: null,
        import_no: null,
        export_no: null,
        job_no: null,
        job_date: null,
        trans_mode: null,
        are1_no: null,
        are1_date: null,
        branch: null,
        be_type: null,
        importer_name: null,
        consignor_name: null,
        ref_no: null,
        country_of_shipment: null,
        port_of_shipment: null,
        mother_vessel_name: null,
        v_no: null,
        eta: null,
        remainder: false,
        feeder_vessel_name: null,
        feeder_v_no: null,
        feeder_eta: null,
        forwarder: null,
        flight_no: null,
        flight_date: null,
        fcl_lcl: null,
        shipping_line: null,
        rotation: null,
        rotation_date: null,
        line_no: null,
        mbl_no: '',
        mbl_date: null,
        hbl_no: null,
        hbl_date: null,
        cbm: null,
        cfs: null,
        net_wt: null,
        net_wt_unit: null,
        gross_wt: null,
        gross_wt_unit: null,
        goods_rcvd_on: null,
        charges_wt: null,
        charges_wt_unit: null,
        num_of_pkgs: null,
        num_of_pkgs_unit: null,
        container_no: null,
        stc: null,
        stc_unit: null,
        stc2: null,
        stc2_unit: null,
        size_20: null,
        size_40: null,
        total_container: null,
        remarks: null,
        terms: null,
        currency: null,
        invoice: null,
        invoice_date: null,
        po: null,
        po_date: null,
        invoice_description: null,
        qty: null,
        unit: null,
        unit_price: null,
        unit_value: null,
        freight: null,
        insurance: null,
        other_charges: null,
        fob_value: null,
        air_line: null,
        rotation_no: null,
        air_line_date: null,
        m_awb_no: null,
        m_awb_date: null,
        h_awb_no: null,
        h_awb_date: null,
        inbond_be_no: null,
        inbond_date: null,
        ass_value: null,
        duty_amount: null,
        bond_no: null,
        bond_date: null,
        bond_expiry_date: null,
        warehouse: null,
        place_of_shipment: null,
        place_of_delivery: null,
        sb_type: null,
        certificate_no: null,
        certificate_date: null
    });

    const [lastRecordId, setLastRecordId] = useState(0);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [isInvoicePopupOpen, setIsInvoicePopupOpen] = useState(false);
    const [isFreightPopupOpen, setIsFreightPopupOpen] = useState(false);
    const [errors, setErrors] = useState({});
    const [containerErrors, setContainerErrors] = useState({});

    const generateRefNo = async () => {
        try {
            const response = await fetch(process.env.REACT_APP_BACKEND_URL + 'import-job/get-latest-record-number');

            if (!response.ok) {
                throw new Error('Failed to fetch the latest record number');
            }

            const data = await response.json();
            console.log(data);
            const incrementedRecordNumber = data.latestRecordNumber;

            const year = new Date().getFullYear();
            const yearSuffix = year % 100;
            const serviceTypeInitial = formData.service_type[0].toUpperCase();
            const transModeInitial = formData.trans_mode[0].toUpperCase();
            const nameInitial = formData.service_type === 'Imports'
                ? formData.importer_name[0].toUpperCase()
                : formData.consignor_name[0].toUpperCase();

            const refNo = `${yearSuffix}${serviceTypeInitial}${transModeInitial}${nameInitial}${incrementedRecordNumber}`;

            setFormData(prevData => ({
                ...prevData,
                ref_no: refNo
            }));

        } catch (error) {
            console.error('Error generating reference number:', error);
        }
    };





    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: type === 'checkbox' ? checked : value
        }));
    };
    const handleFormChange = (e) => {
        const { name, value } = e.target;
        setContainerData(prevData => ({
            ...prevData,
            [name]: value
        }))
    }

    const isValidContainerNo = (container_no) => {
        let pattern = /^[A-Z0-9]{4}[0-9]{7}$/
        return pattern.test(container_no);
    }
    const isValidTotalContainer = (total_container) => {
        let pattern = /^(?:[1-9]\d*)$/;
        return pattern.test(total_container);
    }
    const validateContainer = () => {
        let newError = {};
        if (containerData?.ref_no === '') {
            newError.ref_no = 'Ref no is required!'
        }
        if (containerData?.container_no === '') {
            newError.container_no = 'Container no is required!'
        } else if (!isValidContainerNo(containerData?.container_no)) {
            newError.container_no = 'Container no should have 4 alphanumeric Uppercase characters and 7 numbers!'
        }
        if (containerData?.seal_no === '') {
            newError.seal_no = 'Seal no is required!'
        }
        if (containerData?.size === '') {
            newError.size = 'Size is required!'
        }
        if (containerData?.seal_no === '') {
            newError.seal_no = 'Seal no is required!'
        }
        if (containerData?.pkgs === '') {
            newError.pkgs = 'pkgs is required!'
        }
        if (containerData?.pkgs_uom === '') {
            newError.pkgs_uom = 'Pkgs units is required!'
        }
        if (containerData?.gross_wt === '') {
            newError.gross_wt = 'Gross weight is required!'
        }
        if (containerData?.gross_wt_uom === '') {
            newError.gross_wt_uom = 'Gross weight unit is required!'
        }
        setContainerErrors(newError);
        return Object.keys(newError).length === 0;
    }

    const saveContainer = async (e) => {
        e.preventDefault()
        const isValid = validateContainer();
        if (isValid) {
            try {
                const response = await axios.post(`${BASE_URL}/add-container-details`, containerData);
                console.log(response.data)
                alert(response?.data?.message)
            } catch (error) {
                console.log(error)
                alert(error?.response?.data?.message)
            }
        }
    }
    const validateForm = () => {
        let newError = {};

        if (formData?.date === null) {
            newError.date = ' Date is Required!'
        }
        if (formData.importer_name === null) {
            newError.importer_name = 'Importer name is required!.'
        }
        if (formData.consignor_name === null) {
            newError.importer_name = 'Consignor name is required!.'
        }
        if (formData.trans_mode === 'Sea' && formData?.fcl_lcl === 'LCL') {
            if (formData?.container_no === null) {
                newError.container_no = 'Container no is required!'
            } else if (!isValidContainerNo(formData?.container_no)) {
                newError.container_no = 'Container no should have 4 alphanumeric Uppercase characters and 7 numbers!'
            }
        }
        if (formData?.trans_mode === 'Sea' && formData?.fcl_lcl === 'FCL') {
            if (formData?.total_container === null) {
                newError.total_container = 'Total container is required'
            } else if (!isValidTotalContainer(formData?.total_container)) {
                newError.total_container = 'Container number should not be less than 1!'
            }
        }
        if (formData?.trans_mode === 'Sea') {
            if (formData?.fcl_lcl === null) {
                newError.fcl_lcl = 'Fcl or lcl is required!'
            }
        }
        setErrors(newError);
        return Object.keys(newError).length === 0;
    }
    const handleSave = async (event) => {
        event.preventDefault();
        const isValid = validateForm();
        if (isValid) {
            try {

                await axios.post(BASE_URL, formData);
                alert('JOB REGISTRATION SUCCESSFULL.')
                setSuccessMessage('Job registered successfully');
                setErrorMessage('');
                setTimeout(() => {
                    setSuccessMessage('');
                }, 10000);


            } catch (err) {
                console.error('Error saving job:', err);
                setErrorMessage('Error saving job');
                alert('Error saving the job');
                setSuccessMessage('');
            }
        } else {
            console.log('validation failed')
        }

    };



    console.log(formData)
    return (
        <div className="w-full  bg-white    py-2 ">
            <div className='w-full  h-full my-6 py-2   px-4'>
                <form onSubmit={handleSave}>
                    <div className='flex items-center gap-x-2'>
                        <div>
                            <label>Date: </label>
                            <input
                                type="date"
                                className='ml-2 py-1 px-4 rounded-md border border-gray-300'
                                value={formData?.date || ''}
                                name="date"
                                onChange={handleChange}
                            />
                            <br />
                            {errors?.date && <span className='text-red-400 text-sm text-center'>{errors?.date}</span>}
                        </div>
                        <div>
                            <label>Service type:</label>
                            <select
                                name="service_type"
                                onChange={handleChange}
                                className='w-[200px] rounded-md h-8 border border-gray-300 ml-2'
                            >
                                <option>Select service type</option>
                                <option value="Imports">Imports</option>
                                <option value="Exports">Exports</option>
                            </select>
                        </div>
                        {formData?.service_type === null && (
                            <div>
                                <label>Imp/Exp No</label>
                                <input
                                    type="text"
                                    className='ml-2 py-1 px-4 rounded-md border border-gray-300'
                                    value={formData?.import_no}
                                    name="import_no"
                                    onChange={handleChange}
                                />
                            </div>
                        )}
                        {
                            formData?.service_type === 'Imports' && (<div>
                                <label>Import no:</label>
                                <input
                                    type="text"
                                    className='ml-2 py-1 px-4 rounded-md border border-gray-300'
                                    value={formData?.import_no}
                                    name="import_no"
                                    onChange={handleChange}
                                />
                            </div>)}
                        {formData?.service_type === 'Exports' && (<div>
                            <label>Export no:</label>
                            <input
                                type="text"
                                className='ml-2 py-1 px-4 rounded-md border border-gray-300'
                                value={formData?.export_no}
                                name="export_no"
                                onChange={handleChange}
                            />
                        </div>)}



                        <div>
                            <label>JOB/ID No:</label>
                            <input
                                type="text"
                                className='ml-2 py-1 px-4 rounded-md border border-gray-300'
                                value={formData?.job_no}
                                name="job_no"
                                onChange={handleChange}
                            />
                        </div>
                        <div>
                            <label>Job Date:</label>
                            <input
                                type="date"
                                name="job_date"
                                className='ml-2 py-1 px-4 rounded-md border border-gray-300'
                                value={moment(formData?.job_date).format("YYYY-MM-DD")}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    <div className='w-full h-[1px] bg-gray-200 my-2'></div>
                    <div className='flex items-center space-x-2'>
                        <div>
                            <label>Trans Mode:</label>
                            <select
                                name="trans_mode"
                                onChange={handleChange}
                                className='w-[200px] rounded-md h-8 border border-gray-300 ml-2 text-sm'
                            >
                                <option>Select trans mode</option>
                                {transportMode.map((el, i) => (
                                    <option key={i} value={el.name}>{el.name}</option>
                                ))}
                            </select>
                        </div>
                        {formData?.trans_mode === 'Sea' && (
                            <div className='mx-4'>
                                <label>FCL/LCL</label>
                                <select
                                    name="fcl_lcl"
                                    onChange={handleChange}
                                    className='w-[200px] rounded-md h-8 border border-gray-300 ml-2 text-sm'
                                >
                                    <option>{formData?.fcl_lcl ? formData?.fcl_lcl : 'Select'}</option>
                                    <option value="FCL">FCL</option>
                                    <option value="LCL">LCL</option>
                                </select>
                                <br />
                                {errors?.fcl_lcl && <span className='w-full text-sm text-red-400 font-bold'>{errors?.fcl_lcl}</span>}
                            </div>
                        )}
                        <div>
                            <label>Branch</label>
                            <select
                                name="branch"
                                onChange={handleChange}
                                className='w-[200px] rounded-md h-8 border border-gray-300 ml-2 text-sm'
                            >
                                <option>Select branch</option>
                                <option value="chennai">Chennai</option>
                                <option value="banglore">Banglore</option>
                            </select>
                        </div>
                        <div className='pl-4'>
                            <label>{formData?.service_type === null && 'B/E TYPE'}</label>
                            <label>{formData?.service_type === 'Imports' && 'B/E TYPE'}</label>
                            <label>{formData?.service_type === 'Exports' && 'S/B TYPE'}</label>
                            {formData?.service_type === null && (
                                <select name="be_type" onChange={handleChange} className='w-[200px] border border-gray-300 h-[35px] ml-2' >
                                    <option>Select</option>
                                    <option value="SEZ">SEZ</option>
                                    <option value="HOME">HOME</option>
                                    <option value="IN BOND">IN BOND</option>
                                    <option value="EX BOND">EX BOND</option>
                                    <option value="DTA">DTA</option>
                                    <option value="BOE">BOE</option>
                                    <option value="TRANSFER">TRANSFER</option>
                                </select>
                            )}

                            {formData?.service_type === 'Imports' && (
                                <select name="be_type" onChange={handleChange} className='w-[200px] border border-gray-300 h-[35px] ml-2'>
                                    <option>Select be type</option>
                                    {(formData?.trans_mode === 'Air') && (
                                        <>
                                            <option value="SEZ">SEZ</option>
                                            <option value="HOME">HOME</option>
                                            <option value="IN BOND">IN BOND</option>
                                            <option value="EX BOND">EX BOND</option>
                                        </>
                                    )}
                                    {(formData?.trans_mode === 'Sea') && (
                                        <>
                                            <option value="SEZ">SEZ</option>
                                            <option value="HOME">HOME</option>
                                            <option value="IN BOND">IN BOND</option>
                                            <option value="EX BOND">EX BOND</option>
                                        </>
                                    )}
                                    {(formData?.trans_mode === 'Road') && (
                                        <>
                                            <option value="DTA">DTA</option>
                                            <option value="TRANSFER">TRANSFER</option>

                                        </>
                                    )}
                                    {(formData?.trans_mode === 'Courier') && (
                                        <>
                                            <option value="SEZ">SEZ</option>
                                            <option value="HOME">HOME</option>

                                        </>
                                    )}
                                </select>
                            )}
                            {/* exports */}
                            {formData?.service_type === 'Exports' && (
                                <select name="sb_type" onChange={handleChange} className='w-[200px] border border-gray-300 h-[35px] ml-2'>
                                    <option>Select be type</option>
                                    {(formData?.trans_mode === 'Air') && (
                                        <>
                                            <option value="SEZ">SEZ</option>
                                            <option value="HOME">HOME</option>
                                        </>
                                    )}
                                    {(formData?.trans_mode === 'Sea') && (
                                        <>
                                            <option value="SEZ">SEZ</option>
                                            <option value="HOME">HOME</option>
                                        </>
                                    )}
                                    {(formData?.trans_mode === 'Road') && (
                                        <>
                                            <option value="BOE">BOE</option>
                                            <option value="TRANSFER">TRANSFER</option>

                                        </>
                                    )}
                                    {(formData?.trans_mode === 'Courier') && (
                                        <>
                                            <option value="SEZ">SEZ</option>
                                            <option value="HOME">HOME</option>

                                        </>
                                    )}
                                </select>
                            )}
                        </div>
                    </div>
                    <div className='w-full h-[1px] bg-gray-200 my-2'></div>
                    <div>
                        <div className='flex items-center space-x-2'>
                            <div>
                                <label>IMPORTER NAME</label>
                                <input
                                    type="text"
                                    name="importer_name"
                                    value={formData.importer_name}
                                    onChange={handleChange}
                                    className='w-full py-1 px-4 border border-gray-300'
                                />
                                <br />


                            </div>
                            <div>
                                <label>CONSIGNOR NAME</label>
                                <input
                                    type="text"
                                    name="consignor_name"
                                    value={formData.consignor_name}
                                    onChange={handleChange}
                                    className='w-full py-1 px-4 border border-gray-300'
                                />
                                <br />

                            </div>

                            <div>
                                <button type='button' className='px-4 py-1 bg-blue-700 text-white flex-col mt-6 justify-center items-center mr-4' onClick={generateRefNo}>Get ref no</button>
                                <span className='text-blue-700 text-bold'>{formData?.ref_no}</span>
                                {errors?.importer_name && <span className='text-red-400 text-sm text-center'>{errors?.importer_name}</span>}
                                {errors?.consignor_name && <span className='text-red-400 text-sm text-left'>{errors?.consignor_name}</span>}
                            </div>
                        </div>
                    </div>
                    {/* Shippment details */}
                    <div className='my-4'>
                        <h1 className='font-bold text-lg'>Shipment details</h1>
                        {formData?.service_type === null && formData?.trans_mode === null && (
                            <ShipmentSea
                                formData={formData}
                                units={units}
                                handleChange={handleChange}
                            />
                        )}
                        {/* Air */}
                        {(formData?.service_type === 'Imports' && formData?.trans_mode === 'Air' && formData?.be_type === 'SEZ') && (
                            <ShipmentDetails
                                formData={formData}
                                units={units}
                                handleChange={handleChange}
                            />
                        )}
                        {(formData?.service_type === 'Imports' && formData?.trans_mode === 'Air' && formData?.be_type === 'HOME') && (
                            <ShipmentDetails
                                formData={formData}
                                units={units}
                                handleChange={handleChange}
                            />
                        )}
                        {(formData?.service_type === 'Imports' && formData?.trans_mode === 'Air' && formData?.be_type === 'IN BOND') && (
                            <ShipmentDetails
                                formData={formData}
                                units={units}
                                handleChange={handleChange}
                            />
                        )}
                        {(formData?.service_type === 'Imports' && formData?.trans_mode === 'Air' && formData?.be_type === 'EX BOND') && (
                            <ExBond
                                formData={formData}
                                handleChange={handleChange}
                            />
                        )}
                        {/* sea */}
                        {(formData?.service_type === 'Imports' && formData?.trans_mode === 'Sea' && formData?.be_type === 'SEZ') && (
                            <ShipmentSea
                                formData={formData}
                                units={units}
                                handleChange={handleChange}
                                errors={errors}
                            />
                        )}
                        {(formData?.service_type === 'Imports' && formData?.trans_mode === 'Sea' && formData?.be_type === 'HOME') && (
                            <ShipmentSea
                                formData={formData}
                                units={units}
                                handleChange={handleChange}
                                errors={errors}

                            />
                        )}
                        {(formData?.service_type === 'Imports' && formData?.trans_mode === 'Sea' && formData?.be_type === 'IN BOND') && (
                            <ShipmentSea
                                formData={formData}
                                units={units}
                                handleChange={handleChange}
                                errors={errors}

                            />
                        )}
                        {(formData?.service_type === 'Imports' && formData?.trans_mode === 'Sea' && formData?.be_type === 'EX BOND') && (
                            <ExBond
                                formData={formData}
                                handleChange={handleChange}
                            />
                        )}
                        {/* road */}
                        {(formData?.service_type === 'Imports' && formData?.trans_mode === 'Road' && formData?.be_type === 'DTA') && (
                            <ShipmentRoad
                                formData={formData}
                                units={units}
                                handleChange={handleChange}
                            />
                        )}
                        {(formData?.service_type === 'Imports' && formData?.trans_mode === 'Road' && formData?.be_type === 'TRANSFER') && (
                            <Transfer
                                formData={formData}
                                units={units}
                                handleChange={handleChange}
                                serviceType={formData?.service_type}
                            />
                        )}
                        {/* Courier */}
                        {(formData?.service_type === 'Imports' && formData?.trans_mode === 'Courier' && formData?.be_type === 'SEZ') && (
                            <Courier
                                formData={formData}
                                units={units}
                                handleChange={handleChange}
                            />
                        )}
                        {(formData?.service_type === 'Imports' && formData?.trans_mode === 'Courier' && formData?.be_type === 'HOME') && (
                            <Courier
                                formData={formData}
                                units={units}
                                handleChange={handleChange}
                            />
                        )}
                        {/* Export  Air */}
                        {(formData?.service_type === 'Exports' && formData?.trans_mode === 'Air' && formData?.sb_type === 'SEZ') && (
                            <ShipmentDetails
                                formData={formData}
                                units={units}
                                handleChange={handleChange}
                            />
                        )}
                        {(formData?.service_type === 'Exports' && formData?.trans_mode === 'Air' && formData?.sb_type === 'HOME') && (
                            <ShipmentDetails
                                formData={formData}
                                units={units}
                                handleChange={handleChange}
                            />
                        )}
                        {/* Export Sea */}
                        {(formData?.service_type === 'Exports' && formData?.trans_mode === 'Sea' && formData?.sb_type === 'SEZ') && (
                            <ShipmentSea
                                formData={formData}
                                units={units}
                                handleChange={handleChange}
                                errors={errors}

                            />
                        )}
                        {(formData?.service_type === 'Exports' && formData?.trans_mode === 'Sea' && formData?.sb_type === 'HOME') && (
                            <ShipmentSea
                                formData={formData}
                                units={units}
                                handleChange={handleChange}
                                errors={errors}

                            />
                        )}
                        {/* Exports Road */}
                        {(formData?.service_type === 'Exports' && formData?.trans_mode === 'Road' && formData?.sb_type === 'BOE') && (
                            <ShipmentBoe
                                formData={formData}
                                units={units}
                                handleChange={handleChange}
                            />
                        )}
                        {(formData?.service_type === 'Exports' && formData?.trans_mode === 'Road' && formData?.sb_type === 'TRANSFER') && (
                            <Transfer
                                formData={formData}
                                units={units}
                                handleChange={handleChange}
                                serviceType={formData?.service_type}
                            />
                        )}
                        {/* Exports Courier */}
                        {(formData?.service_type === 'Exports' && formData?.trans_mode === 'Courier' && formData?.sb_type === 'SEZ') && (
                            <Courier
                                formData={formData}
                                units={units}
                                handleChange={handleChange}
                            />
                        )}
                        {(formData?.service_type === 'Exports' && formData?.trans_mode === 'Courier' && formData?.sb_type === 'HOME') && (
                            <Courier
                                formData={formData}
                                units={units}
                                handleChange={handleChange}
                            />
                        )}
                    </div>
                    {formData?.fcl_lcl === 'FCL' && (
                        <div>
                            <h3 className='text-lg mt-2 text-gray-500 font-semibold'>Add Container details</h3>
                            <button type="button" className='w-[100px] px-4 py-1 bg-orange-500 text-white rounded-md' onClick={() => document.getElementById('my_modal_1').showModal()}> Add</button>
                            <ContainerModal
                                containerData={containerData}
                                handleFormChange={handleFormChange}
                                units={units}
                                saveContainer={saveContainer}
                                errors={containerErrors}
                            />
                        </div>
                    )}
                    <div className='my-6'>
                        <h3 className='text-lg font-semibold'>Invoice details</h3>
                        <button type="button" className=" px-4 py-1 bg-blue-500 text-white" onClick={() => document.getElementById('my_modal_4').showModal()}> Add here</button>
                        <InvoiceModal
                            formData={formData}
                            units={units}
                            currency={formData?.currency}
                            currencies={currencies}
                            handleChange={handleChange}
                        />
                    </div>
                    <div className='my-6 '>
                        <h3 className='text-lg font-semibold'>Freight Details</h3>
                        <button type="button" className="px-4 py-1 bg-blue-500 text-white" onClick={() => document.getElementById('my_modal_5').showModal()}>Add Here</button>
                        <FreightModal
                            formData={formData}
                            units={units}
                            currencies={currencies}
                            handleChange={handleChange}
                        />
                    </div>
                    <div className='w-[400px] mx-auto text-center pb-14'>
                        <button className='w-[200px] mx-auto px-2 py-2 bg-blue-500 text-white'>SUBMIT</button>
                    </div>
                </form>
            </div >
        </div >
    );
};

export default ImportJobRegistration;

