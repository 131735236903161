import axios from 'axios';
import { HiCheckCircle } from "react-icons/hi";
import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'

const BASE_URL = process.env.REACT_APP_BACKEND_URL;

const UnbilledInformation = () => {
  const [billingData, setBillingData] = useState([]);
  const [refNo, setRefNo] = useState('');
  const [before, setBefore] = useState(null);
  const [after, setAfter] = useState(null);
  const [party, setParty] = useState("");
  const [records, setRecords] = useState([]);
  const [open, setOpen] = useState(false);
  const [year, setYear] = useState("");
  const menuRef = useRef();

  const handleSearch = async () => {
    try {
      if (refNo !== '') {
        const response = await axios.get(BASE_URL + `import-job/search?q=${refNo}`);
        setRecords(response.data);
        setOpen(true)
      }
    } catch (error) {
      console.error('Error fetching records:', error);
    }
  };

  const handleRecordSelect = (record) => {
    setRefNo(record?.ref_no);
    setOpen(false);
  };

  const listUnbilledData = async () => {
    try {
      const base_url = process.env.REACT_APP_BACKEND_URL;

      let response = await axios.get(base_url + `billing/search/unbilled`);
      setBillingData(response.data);
    } catch (error) {
      alert('Error fetching data')
    }
  }
  useEffect(() => {
    if (refNo !== '') {
      handleSearch()
    }
    listUnbilledData()
    const handler = (e) => {
      if (!menuRef.current.contains(e.target)) {
        setOpen(false);
      }

    }
    document.addEventListener('mousedown', handler)
    return () => {
      document.removeEventListener("mousedown", handler);
    }
  }, [refNo])

  const handleFilterSearch = async (e) => {
    e.preventDefault();
    try {
      const base_url = process.env.REACT_APP_BACKEND_URL;

      if (refNo) {
        let response = await axios.get(base_url + `billing/search/unbilled?refNo=${refNo}`);
        setBillingData(response.data);
      } else if (before) {
        let response = await axios.get(base_url + `billing/search/unbilled?before=${before}`);
        setBillingData(response.data);
      } else if (after) {
        let response = await axios.get(base_url + `billing/search/unbilled?after=${after}`);
        setBillingData(response.data);
      }

    } catch (error) {
      alert(error?.response.data?.message);
    }
  }
  console.log(year);
  return (
    <div className='h-full bg-white mt-2 w-full px-4'>
      <div className='flex'>
        <div className='bg-blue-500 text-white  w-[340px] px-4 py-4 h-screen'>
          <form onSubmit={handleFilterSearch}>
            <div>
              <label className='text-white'>Before Date</label>
              <input
                type="date"
                className='w-full h-8 text-black px-2 rounded-sm mt-2'
                placeholder='Before date'
                value={before || ''}
                onChange={e => setBefore(e.target.value)}
              />
            </div>
            <div className='mt-4'>
              <label className='text-white'>After Date</label>
              <input
                type="date"
                className='w-full h-8 text-black px-2 rounded-sm mt-2'
                placeholder='Before date'
                value={after || ''}
                onChange={e => setAfter(e.target.value)}
              />
            </div>
            <div className='relative' ref={menuRef}>
              <div className='mt-4'>
                <label className='text-white'>Search by Ref no</label>
                <input
                  type="text"
                  className='w-full h-8 text-black px-2 rounded-sm mt-2 outline-0 text-sm'
                  placeholder='Search here....'
                  value={refNo || ''}
                  onChange={e => setRefNo(e.target.value)}
                />
              </div>
              {records.length > 0 && open && (
                <ul className='mt-2 bg-white max-h-[300px] overflow-y-auto absolute w-full z-40'>
                  {records.map(record => (
                    <li key={record.id} className='text-gray-400 px-4 text-sm  mt-4 mx-2 cursor-pointer bg-gray-200 mb-2 py-2 hover:bg-gray-100' onClick={() => handleRecordSelect(record)}>{record?.ref_no}</li>
                  ))}
                </ul>
              )}
            </div>
            <button className='w-[100%] h-[40px] mt-6 bg-yellow-400'>Search</button>
          </form>

        </div>
        <div className='h-screen bg-white flex-1'>
          {billingData.length > 0 && (
            <div>
              <table className='table'>
                <thead>
                  <tr>
                    <th>Refno</th>
                    <th>Date</th>
                    <th>Importer Name</th>
                    <th>Consignor Name</th>
                    <th>Clearance date</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {billingData.map((b, i) => (
                    <tr key={i}>
                      <td>{b?.ref_no}</td>
                      <td>{b?.date}</td>
                      <td>{b?.importer_name}</td>
                      <td>{b?.consignor_name}</td>
                      <td>{b?.cleared_on}</td>
                      <td className='cursor-pointer'>
                        <Link to="/accounts/billing">
                          <HiCheckCircle size={22} color='oklch(0.792 0.209 151.711)' />
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default UnbilledInformation