import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import './styles/FileUpload.css';
import moment from 'moment';
import { FaSearch } from 'react-icons/fa';
import Search from './search/Search';

const BASE_URL = process.env.REACT_APP_BACKEND_URL + 'file-upload';

const FileUploadDetailsReport = () => {
  const [refNo, setRefNo] = useState('');
  const [open, setOpen] = useState(false);
  const [records, setRecords] = useState([]);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [files, setFiles] = useState([]);
  const [error, setError] = useState('');
  const [beforeDate, setBeforeDate] = useState('');
  const [afterDate, setAfterDate] = useState('');
  const menuRef = useRef();

  const handleSearch = async () => {
    const BASE_URL = process.env.REACT_APP_BACKEND_URL
    try {
      if (refNo !== '') {
        const response = await axios.get(BASE_URL + `import-job/search?q=${refNo}`);
        console.log(response.data);
        setRecords(response?.data);
        setOpen(true);
      }
    } catch (error) {
      alert(error?.response?.data?.message)
      console.error('Error fetching records:', error);
    }
  };

  const handleRecordSelect = (record) => {
    setRefNo(record?.ref_no);
    setSelectedRecord(record?.ref_no);
    setOpen(false);
  };
  useEffect(() => {
    if (refNo !== '') {
      handleSearch();
    }
    const handler = (e) => {
      if (!menuRef.current.contains(e.target)) {
        setOpen(false);
      }
    }
    document.addEventListener('mousedown', handler)
    return () => {
      document.removeEventListener("mousedown", handler);
    }
  }, [refNo]);


  // const fetchFiles = async (refNo, filterType, filterDate) => {
  //   try {
  //     let url = `${BASE_URL}/report`;
  //     if (refNo) {
  //       url += `/${refNo}`;
  //     }

  //     const response = await axios.get(url, {
  //       params: { filterType, filterDate }
  //     });
  //     setFiles(response.data);
  //   } catch (err) {
  //     console.error('Error fetching files:', err);
  //     setError('Error fetching files');
  //   }
  // };




  const handleDownload = (filePath) => {
    const fileName = filePath.split('/').pop().split('\\').pop();
    window.open(`${BASE_URL}/download/${encodeURIComponent(fileName)}`, '_blank');
  };

  const handleInputChange = (e) => {
    setRefNo(e.target.value);
    // if (e.target.value) {
    //   fetchFiles(e.target.value); // Optional: Fetch files as user types
    // }
  };






  const handleViewClick = async () => {
    // fetchFiles(refNo || null, filterType, filterDate);
    try {
      const base_url = process.env.REACT_APP_BACKEND_URL;
      if (beforeDate) {
        let response = await axios.get(`${base_url}/search?ref_no=${refNo}&before=${beforeDate}`);
        console.log(response.data);
        setFiles(response.data);
      } else if (afterDate) {
        let response = await axios.get(`${base_url}/search?ref_no=${refNo}&after=${afterDate}`);
        console.log(response.data);
        setFiles(response.data)
      }


    } catch (error) {
      alert(error?.response.data?.message);
    }
  }

  return (
    <div className="bg-white py-4">
      <h1 className="file-upload-title">File Upload Details Report</h1>

      <div className='relative' ref={menuRef}>
        <div className='w-[350px] mx-auto flex items-center space-x-4 border border-gray-400 px-2 rounded-[5px]'>
          <input
            type="text"
            value={refNo || ''}
            onChange={(e) => setRefNo(e.target.value)}
            className='w-full  px-2 py-2 rounded-sm outline-0'
            placeholder='Search reference no'
          />
          <span className='text-blue-600 font-bold text-sm'>{selectedRecord?.ref_no}</span>
          <FaSearch size='16' />
        </div>
        {(records.length > 0 && open) && (
          <ul className='w-[350px] max-h-[300px] overflow-y-auto shadow-lg bg-white px-2 mt-1 absolute left-[525px] z-30'>
            {records.map(record => (
              <li key={record?.id} className='bg-gray-50 mb-2 px-2 py-1 hover:bg-gray-200 cursor-pointer' onClick={() => handleRecordSelect(record)}>
                <span className='text-sm text-black font-normal'>{record?.ref_no}</span>
              </li>
            ))}
          </ul>
        )}
      </div>

      <div className=' w-[600px] mx-auto my-6 flex items-center space-x-4'>
        <div>
          <label>Filter by Before Date</label>
          <br />
          <input
            type="date"
            value={moment(beforeDate).format("YYYY-MM-DD") || ''}
            onChange={e => setBeforeDate(e.target.value)}
            className='w-[300px] h-[40px] rounded-md px-2 outline-0 border border-gray-400'
          />
        </div>
        <div>
          <label>Filter by After Date</label>
          <br />
          <input
            type="date"
            value={moment(afterDate).format("YYYY-MM-DD") || ''}
            onChange={e => setAfterDate(e.target.value)}
            className='w-[300px] h-[40px] rounded-md px-2 outline-0 border border-gray-400'
          />
        </div>
        <button onClick={handleViewClick} className=" px-6 mt-5 py-[10px] text-white text-sm bg-green-600">
          View
        </button>
      </div>
      {
        files.length > 0 && (
          <div className="w-full mt-8 bg-white">
            <h2 className="file-upload-list-title">Uploaded Files</h2>
            <table className="table">
              <thead>
                <tr>
                  <th>Reference No</th>
                  <th>File Name</th>
                  <th>Uploaded Time</th>
                </tr>
              </thead>
              <tbody>
                {files.map((file) => (
                  <tr key={file.id}>
                    <td>{refNo}</td>
                    <td>{JSON.parse(file?.job_files).map((f, i) => (
                      <li key={i} className='list-none font-bold text-sm text-gray-500'>{f.fileName}</li>
                    ))}</td>
                    <td>{moment.utc(file.created_at).format('MMMM Do YYYY, HH:mm:ss ')}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )
      }
    </div >
  );
};

export default FileUploadDetailsReport;
