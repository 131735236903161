import React from 'react'

const FreightModal = ({ formData, handleChange }) => {
    return (
        <dialog id="my_modal_5" className="w-[400px]  mx-auto mt-[90px] shadow-lg rounded-md px-4 py-4">
            <div className="mx-auto">
                <h3 className="font-bold text-lg">Freight Details</h3>
                <div className='my-4'>
                    <div className='my-4'>
                        <label>Freight</label>
                        <input
                            type="text"
                            name="freight"
                            value={formData?.freight}
                            onChange={handleChange}
                            className='w-full h-8 border border-gray-400 rounded-md'
                        />
                    </div>
                    <div className='my-4'>
                        <label>Insurance</label>
                        <input
                            type="text"
                            name="insurance"
                            value={formData?.insurance}
                            onChange={handleChange}
                            className='w-full h-8 border border-gray-400 rounded-md'
                        />
                    </div>
                    <div className='my-4'>
                        <label>Other Charges</label>
                        <input
                            type="text"
                            name="other_charges"
                            value={formData?.other_charges}
                            onChange={handleChange}
                            className='w-full h-8 border border-gray-400 rounded-md'
                        />
                    </div>
                    <div className='my-4'>
                        <label>Fob</label>
                        <input
                            type="text"
                            name="fob_value"
                            value={formData?.fob_value}
                            onChange={handleChange}
                            className='w-full h-8 border border-gray-400 rounded-md'
                        />
                    </div>
                </div>
                <div className="modal-action">
                    <form method="dialog">
                        {/* if there is a button, it will close the modal */}
                        <button className="px-4 py-1 bg-blue-500 text-white ">Close</button>
                    </form>
                </div>
            </div>
        </dialog>
    )
}

export default FreightModal