export const expenseType = [
    { id: 1, name: 'SEZ1' },
    { id: 2, name: 'Airport' },
    { id: 3, name: 'Seaport' },
    { id: 4, name: 'Accounts' },
    { id: 5, name: 'SEZ-2' }
];

export const transportMode = [
    { id: 1, name: 'Air' },
    { id: 2, name: 'Sea' },
    { id: 3, name: 'Road' },
    { id: 4, name: 'Courier' }
]
export const billData = [
    { id: 1, name: 'paid' },
    { id: 2, name: 'un paid' },
    { id: 3, name: 'balance' },
    { id: 4, name: 'approve' },
    { id: 5, name: ' not approved' }

]